
import classes from './PopupWrapper.module.scss';



function PopupWrapper(props) {
  const { show, setShow, children, wrapperStyle, bodyStyle, closeStyle } = props;
  if (!show) return null;
  return (
    <div className={classes["wrapper"]} style={wrapperStyle}>
      <div className={classes["popup-body"]} style={bodyStyle}>
        <div className={["app-black-close", classes['close']].join(' ')}
          style={closeStyle}
          onClick={() => {
            setShow(prevShow => !prevShow);
          }}
        />
        {children}
      </div>
    </div>
  )
}

export default PopupWrapper;
