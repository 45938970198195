import classes from './TheFooter.module.scss';
import useLocaleInfo from '../../Hooks/useLocaleInfo';
import GlobalStore from '../../contexts/GlobalStore';
import { useContext } from 'react';
import useGoogleAdParams from '../../Hooks/useGoogleAdParams';
import { useIntl } from 'react-intl';

function TheFooter() {
	const GAParams = useGoogleAdParams();
	const intl = useIntl();
	const store = useContext(GlobalStore);
	let campaignUri = "";

	const localeInfo = useLocaleInfo(store.regionCode);

	if (GAParams.cm_campid != null && GAParams.cm_campid != "") {
		campaignUri += "?cm_campid=" + GAParams.cm_campid;
	} else {
		campaignUri += "?cm_campid=" + process.env.REACT_APP_CONTACT_SALE_CODE;
	}

	if (GAParams.utm_campaign != null && GAParams.utm_campaign != "") {
		campaignUri += '&utm_campaign=' + GAParams.utm_campaign;
	}

	if (GAParams.utm_content != null && GAParams.utm_content != "") {
		campaignUri += '&utm_content=' + GAParams.utm_content;
	}

	if (GAParams.utm_id != null && GAParams.utm_id != "") {
		campaignUri += '&utm_id=' + GAParams.utm_id;
	}

	if (GAParams.utm_medium != null && GAParams.utm_medium != "") {
		campaignUri += '&utm_medium=' + GAParams.utm_medium;
	}

	if (GAParams.utm_source != null && GAParams.utm_source != "") {
		campaignUri += '&utm_source=' + GAParams.utm_source;
	}

	if (GAParams.utm_term != null && GAParams.utm_term != "") {
		campaignUri += '&utm_term=' + GAParams.utm_term;
	}

	return (
		<div className={classes.main}>
			<p>{intl.formatMessage({ id: "footer.reportBy" }, { br: <br /> })}</p>

			<p>{intl.formatMessage({ id: "footer.p1" }, { br: <br /> })} <a href={localeInfo.contact_sale_link + campaignUri} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "footer.contact" }, { br: <br /> })}</a>.</p>
		</div>
	);
}

export default TheFooter;