
import classes from './PopupWithFullScreenShadow.module.scss';



function PopupWithFullScreenShadow(props) {
  // eslint-disable-next-line
  const { show, setShow, children } = props;
  return (
    show && <div className={classes.wrapper} >
        <div className={classes["popup-body"]}>
          {/* <div className={["app-black-close", classes['close']].join(' ')}
            onClick={() => {
              setShow(!show);
            }}
          /> */}
          {children}
        </div>
    </div>
  )
}

export default PopupWithFullScreenShadow;
