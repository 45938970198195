
import classes from './ZoneBlock.module.scss';
import { useContext, useEffect, useState, useRef } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import HoverBubble from '../HoverBubble/HoverBubble';
import calcIcon from '../../assets/images/calculator-icon.png'
import { useIntl } from 'react-intl';

const ZoneBlock = (props) => {
  const {
    appHovering,
    setAppHovering,
    facilityAndZone,
    mapImgElRef
  } = props;

  const store = useContext(GlobalStore)
  const intl = useIntl()
  const zonesRef = store.zonesRef
  const id = facilityAndZone['zone_id']
  const zoneAndApplicationRef = useRef(require('../../configs/zoneAndApplication.json'))
  const zoneAndApplications = zoneAndApplicationRef.current.filter(entry => { return entry['zone_id'] === id })

  // eslint-disable-next-line
  const [hovering, setHovering] = useState(true)



  /**
   * 
   * @param {*} clip_path give four points, left, right, up, down. example: polygon(18% 30%, 31% 42%, 56% 17.3%, 43.2% 4.2%)
   * @param {*} scale scale the size of the frame
   * @param {*} ratio set the thickness of the frame related to the % of the frame size, like css "em" unit, If thickness is given, ratio will be ignored.
   * @param {*} thickness specific the thick of the frame, like the css "px" unit. 
   * @returns the browser understandable css clip-path rule
   */
  const getClipFrame = (clip_path, scale, ratio, thickness) => {
    if (!scale) scale = 0
    if (!ratio) ratio = 0.96
    ratio = 1 - ratio
    const pointsString = clip_path.split('(')[1].split(')')[0]
    const getPoints = (pointsString) => {
      const pointStringArray = pointsString.split(',')
      return pointStringArray.map((entry) => {
        return entry.trim().split(' ').map((coordinate) => { return parseFloat(coordinate.slice(0, -1)) })
      })
    }
    const pointsArray = getPoints(pointsString)

    // only support rectangle frame
    if (pointsArray.length !== 4) return clip_path
    pointsArray.sort((a, b) => { return a[0] - b[0] })
    const leftPoint = [pointsArray[0][0] + scale, pointsArray[0][1]]
    const rightPoint = [pointsArray[3][0] - scale, pointsArray[3][1]]
    pointsArray.sort((a, b) => { return a[1] - b[1] })
    const upPoint = [pointsArray[0][0], pointsArray[0][1] + scale]
    // const downPoint = [pointsArray[3][0], pointsArray[3][1]-scale]
    const downPoint = [leftPoint[0] + rightPoint[0] - upPoint[0], leftPoint[1] + rightPoint[1] - upPoint[1]]
    const sortedPointArray = [upPoint, rightPoint, downPoint, leftPoint]


    // calc cutpoint

    let param1 = thickness;
    let param2 = thickness;

    if (!thickness && thickness !== 0) {
      param1 = ratio / 2 * (sortedPointArray[1][0] - sortedPointArray[3][0])
      param2 = ratio / 2 * (sortedPointArray[2][1] - sortedPointArray[0][1])
    }

    const cutPointXLength = (
      param1
      * (sortedPointArray[2][0] - sortedPointArray[3][0]) * (sortedPointArray[2][0] - sortedPointArray[3][0])
      /
      (
        (sortedPointArray[2][0] - sortedPointArray[3][0]) * (sortedPointArray[2][0] - sortedPointArray[3][0]) +
        (sortedPointArray[2][1] - sortedPointArray[3][1]) * (sortedPointArray[2][1] - sortedPointArray[3][1])
      )
    )
    const cutPoint = [
      cutPointXLength + sortedPointArray[3][0],
      cutPointXLength * ((sortedPointArray[2][1] - sortedPointArray[3][1]) / (sortedPointArray[2][0] - sortedPointArray[3][0])) + sortedPointArray[3][1],
    ]
    const innerUpPoint = [
      upPoint[0],
      param2 + sortedPointArray[0][1],
    ]
    const innerRightPoint = [
      sortedPointArray[1][0] - param1,
      rightPoint[1]
    ]
    const innerDownPoint = [
      downPoint[0],
      sortedPointArray[2][1] - param2,
    ]
    const innerLeftPoint = [
      sortedPointArray[3][0] + param1,
      leftPoint[1]
    ]
    const resPointArray = [upPoint, leftPoint, cutPoint, innerUpPoint, innerRightPoint, innerDownPoint, innerLeftPoint, cutPoint, downPoint, rightPoint]

    const cssString = 'polygon(' + resPointArray.map(point => {

      return point.join('% ')
    }).join('%, ') + '%)'
    return cssString
  }
  const styles = {
    zoneMainStyle: {
      clipPath: facilityAndZone['clip_path'],
    },
    frame1Style: {
      clipPath: getClipFrame(facilityAndZone['clip_path'], 0, null, 0.8),
      backgroundColor: 'var(--color-school-bus-yellow)'
    },
    frame1StyleNohover: {
      clipPath: getClipFrame(facilityAndZone['clip_path'], 0, null, 0),
      backgroundColor: 'var(--color-school-bus-yellow)'
    },
    frame2Style: {
      clipPath: getClipFrame(facilityAndZone['clip_path'], 2, null, 0),
      backgroundColor: 'blue'
    },
    frame2StyleNohover: {
      clipPath: getClipFrame(facilityAndZone['clip_path'], 2, null, 0),
      backgroundColor: 'blue'
    }
  }
  const prevXRef = useRef(0);
  const prevYRef = useRef(0);
  const clicking = useRef(false);


  const calcZoomInPosition = (clip_path) => {
    const pointsString = clip_path.split('(')[1].split(')')[0]
    const getPoints = (pointsString) => {
      const pointStringArray = pointsString.split(',')
      return pointStringArray.map((entry) => {
        return entry.trim().split(' ').map((coordinate) => { return coordinate.slice(0, -1) })
      })
    }
    const pointsArray = getPoints(pointsString)
    const getCenterPoint = (pointsArray) => {
      let XSum = 0;
      let YSum = 0;
      for (let i in pointsArray) {
        XSum += parseFloat(pointsArray[i][0])
        YSum += parseFloat(pointsArray[i][1])
      }
      return [XSum / pointsArray.length + '%', YSum / pointsArray.length + '%']
    }

    const centerPoint = getCenterPoint(pointsArray)
    const scale = props.clickZoominScale
    const mapBodyElRef = props.mapBodyElRef
    const mapWindowElRef = props.mapWindowElRef
    const bodyLeftBoundX = -(scale - 1) * mapBodyElRef.current.clientWidth / 2
    const bodyUperBoundY = -(scale - 1) * mapBodyElRef.current.clientHeight / 2

    let positionX = 0.5 * mapWindowElRef.current.clientWidth - (bodyLeftBoundX + mapBodyElRef.current.clientWidth * scale * (parseFloat(centerPoint[0].slice(0, -1)) / 100))
    const positionY = 0.5 * mapWindowElRef.current.clientHeight - (bodyUperBoundY + mapBodyElRef.current.clientHeight * scale * (parseFloat(centerPoint[1].slice(0, -1)) / 100))

    // add extra space to the left index for the left sidebar, the sidebar's width is 50vw, so now added 25% mapBodyElRef's space to the left
    positionX += mapBodyElRef.current.clientWidth * 0.25

    return [positionX, positionY]
  }

  // move window location when zone id change
  useEffect(() => {
    if (store.zoneId === id) {
      if (mapImgElRef.current.complete) {
        const [positionX, positionY] = calcZoomInPosition(facilityAndZone['clip_path'])
        props.updateLimitation(props.clickZoominScale)
        props.setPositionX(positionX)
        props.setPositionY(positionY)
        props.setScale(props.clickZoominScale)

      } else {
        mapImgElRef.current.decode().then(() => {
          const [positionX, positionY] = calcZoomInPosition(facilityAndZone['clip_path'])
          props.updateLimitation(props.clickZoominScale)
          props.setPositionX(positionX)
          props.setPositionY(positionY)
          props.setScale(props.clickZoominScale)
        })
      }
    }
    // eslint-disable-next-line
  }, [store.zoneId])



  return (
    <div
      className={[props.dragging ? classes.move : id === store.zoneId ? classes.normal : classes.pointer].join(' ')}
      onMouseDown={(e) => {
        prevXRef.current = e.pageX
        prevYRef.current = e.pageY
        clicking.current = true
      }}
      onMouseUp={(e) => {
        if (clicking.current && prevXRef.current === e.pageX && prevYRef.current === e.pageY) {
          store.setZoneId(id)
          store.setApplicationId('')
        }
        clicking.current = false
      }}
    // onMouseEnter={() => { setHovering(true) }}
    // onMouseLeave={() => { setHovering(false) }}
    >
      <div className={[classes['zone-main']].join(' ')} style={styles.zoneMainStyle} />


      { // applications
        zoneAndApplications.sort((a, b) => {
          if (a['position_y'] > b['position_y']) {
            return 1
          } else if (a['position_y'] < b['position_y']) {
            return -1
          } else return 0
        }).map((record) => {
          const elStyle = {
            position: 'absolute',
            left: record['position_x'],
            top: record['position_y'],
          }
          const applicationInfo = store.applicationsRef.current[record['application_id']]
          const active = applicationInfo.id === store.applicationId && record.dummy !== "TRUE"
          const ifROI = applicationInfo.calculator_id && applicationInfo.calculator_id !== "null" ? true : false
          if (applicationInfo.visible !== "TRUE") return null;
          else if (record.dummy === "TRUE") {
            return (
              <div style={elStyle} key={record.id} className={[
                id === store.zoneId ? '' : classes.hidden,
                classes['application-main-dummy'],
                classes['application-main']
              ].join(' ')}
                onClick={() => {
                  if (applicationInfo.avaliable === "TRUE")
                    store.setApplicationId(record['application_id'])
                }}
              >
                <img src={"/assets/images/application_icons/" + applicationInfo.icon}
                  alt={
                    applicationInfo.icon_alt ?
                      intl.formatMessage({ id: record['application_id'] + ".icon_alt" }, { br: <br /> })
                      : intl.formatMessage({ id: record['application_id'] + ".name" }, { br: <br /> })
                  } draggable='false'></img>
              </div>
            )
          }
          else return (
            <div style={elStyle} key={record.id} className={[
              // id === store.zoneId ? '' : classes.hidden,
              applicationInfo.avaliable !== "TRUE" ? classes["application-main-unavaliable"] : null,
              active ? classes["application-active"] : null,
              classes['application-main'],
              active && appHovering && appHovering !== record['application_id'] ? classes["fade"] : null
            ].join(' ')}
              onClick={(e) => {
                e.stopPropagation()
                if (applicationInfo.avaliable === "TRUE") {
                  store.setZoneId(id)
                  store.setApplicationId(record['application_id'])
                }
              }}
              onMouseEnter={() => { setAppHovering(record['application_id']) }}
              onMouseLeave={() => { setAppHovering("") }}
            >
              <img src={"/assets/images/application_icons/" + applicationInfo.icon} alt={applicationInfo.icon_alt ? applicationInfo.icon_alt : applicationInfo.name} draggable='false' />
              {ifROI && <img src={calcIcon} alt="calc-icon" draggable='false' className={classes['calc-icon']} />}
              {
                applicationInfo.name === "" ?
                  null :
                  <div className={[classes['application-hover'],
                  active ? classes['application-active'] : null
                  ].join(' ')}>
                    <HoverBubble hoverDirection={"top"} hoverX={'50%'} hoverY={'0%'} backgroundColor={ifROI ? "var(--color-school-bus-yellow)" : "#016ac0"} showBorder={false} size={16}>
                      <p className={[classes['application-hover-p'], ifROI ? null : classes['blue-bg']].join(' ')} >
                        {intl.formatMessage({ id: record['application_id'] + ".name" }, { br: <br /> })}
                      </p>
                    </HoverBubble>
                  </div>
              }
            </div>
          )
        })
      }
      <div className={[
        classes['zone-frame'],
      ].join(' ')}
        style={id === store.zoneId || hovering ? styles.frame1Style : styles.frame1StyleNohover}></div>
      <div className={[
        classes['zone-frame'],
        hovering ? classes['zone-frame-delay'] : ''
      ].join(' ')} style={id === store.zoneId || hovering ? styles.frame2Style : styles.frame2StyleNohover}></div>

      <div className={[classes['zone-hover-helper'], store.zoneId ? 'app-display-none' : null].join(' ')}>
        <HoverBubble hoverDirection={facilityAndZone['hover']} hoverX={facilityAndZone['hover_x']} hoverY={facilityAndZone['hover_y']} showBorder={true}>
          <h3>{intl.formatMessage({ id: id + ".name" }, { br: <br /> })}</h3>
          <p>{intl.formatMessage({ id: id + ".copy" }, { br: <br /> })}</p>
        </HoverBubble>
      </div>

    </div>

  )
}

export default ZoneBlock