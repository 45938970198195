import './App.scss';
import { useEffect, useRef, useState } from 'react';
import { Switch, useParams, Route, useLocation } from 'react-router';
import TheHeader from './components/TheHeader/TheHeader';
import TheHome from './pages/TheHome/TheHome';
import ApplicationView from './pages/ApplicationView/ApplicationView.js'
// import TheResults from './pages/TheResults/TheResults.js'
import FacilityMap from './pages/FacilityMap/FacilityMap';
import supportedLang from './configs/languages/langs.json'
import ErrorPage from './pages/ErrorPage/ErrorPage'
import GlobalStore from './contexts/GlobalStore';
import axios from 'axios';
import { useIntl } from 'react-intl';
import useLocaleInfo from './Hooks/useLocaleInfo';
// import { v4 as uuidv4 } from 'uuid';
import useDeviceType from './Hooks/useDeviceType';

// css fot rc-slider
import "rc-slider/assets/index.css";
import UnfitRemainder from './components/UnfitRemainder/UnfitRemainder';
import UpdateRemainder from './components/UpdateRemainder/UpdateRemainder';




function App() {
  const intl = useIntl()
  const params = useParams()
  const location = useLocation()


  // eslint-disable-next-line
  const [facilityId, setFacilityId] = useState('facility_01')
  const [zoneId, setZoneId] = useState("")
  const [applicationId, setApplicationId] = useState("")
  const [showLangSelector, setShowLangSelector] = useState(false)

  const [curPageId, setCurPageId] = useState()

  const [regionCode, setRegionCode] = useState('') /* regionCode is used to determine links for "contact sales" and "request a demo" button, and "contact" button in the email */
  const localeInfo = useLocaleInfo(regionCode)


  // pendingPOST means waiting response from server, disable button
  const [pendingPOST, setPendingPOST] = useState(false)
  // showNID = true if responsed Network Error
  const [showNID, setShowNID] = useState(false)

  // run time data
  const zonesRef = useRef(require('./configs/zones.json'))
  const applicationsRef = useRef(require('./configs/applications.json'))
  const currenciesRef = useRef(require('./configs/currency/currencies.json'))
  const langRef = useRef(require('./configs/languages/langs.json'))
  const solutionsRef = useRef(require('./configs/solutions.json'))
  const [curCurrency, setCurCurrency] = useState('usd')
  const userId = useRef("")
  const [formData, setFormData] = useState({})
  const [configData, setConfigData] = useState({})
  const [ROIAppFormSubmitted, setROIAppFormSubmitted] = useState(false)
  const [NonROIAppFormSubmitted, setNonROIAppFormSubmitted] = useState(false)
  const [ifOffline, setIfOffline] = useState(false)

  const deviceType = useDeviceType()


  const config = {
    hideLangSwitcher: false, // hide the language switcher or not
    lockMapSidebar: true, // Lock the map sidebar or not
    disableAdvSettings: true, // disable advanced settings on the calculator
    enablePWAUpdateRemainder: true, // enable the remainder to notify new app version available
    autoNavTo: {  // Auto nav to an wanted application
      autoNavToOn: false, // If set to false, auto nav won't effect
      facilityId: 'facility_01',
      zoneId: 'zone_02',
      applicationId: '',
    },
    pages: [// list, should follow the render order
      {
        id: 'home-page',
        document_title: 'Cognex ROI Calculator | Home',
        pathname: '',
        title: '',
        public: true  // control page is public or not
      },
      {
        id: 'facility-map',
        document_title: 'Cognex ROI Calculator | Facility Map',
        pathname: 'facility-map',
        title: 'FACILITY',
        public: true
      },
      {
        id: 'application-view',
        document_title: 'Cognex ROI Calculator | ROI',
        pathname: 'roi',
        title: 'APPLICATION VIEW',
        public: true
      },
      // {
      //   id: 'form',
      //   document_title: 'Cognex ROI Calculator | Form',
      //   pathname: 'form',
      //   title: '',
      //   public: true
      // },
      // {
      //   id: 'form-results',
      //   document_title: 'Cognex ROI Calculator | Form Results',
      //   pathname: 'form-results',
      //   title: 'FINAL RESULTS',
      //   public: true
      // }
    ]
  }
  const pages = config.pages
  const pathnames = location.pathname.split('/')
  const levelTwoPage = pathnames[2]
  const headerRef = useRef()


  const getZoneIdByPathname = (pathname) => {
    const res = Object.values(zonesRef.current).filter(entry => {
      return entry.pathname === pathname
    })
    if (res[0])
      return res[0].id
    else {
      return null
    }
  }


  const getApplicationIdByPathname = (pathname) => {
    const res = Object.values(applicationsRef.current).filter(entry => {
      return entry.pathname === pathname
    })
    return res[0] ? res[0].id : null
  }

  const postData = (formData) => {
    const toPost = {};
    toPost['userId'] = userId.current
    toPost['formData'] = {
      ...formData,
    }
    toPost['configData'] = configData
    toPost['facilityId'] = facilityId
    toPost['zoneId'] = zoneId
    toPost['applicationId'] = applicationId
    toPost['email'] = {
      emailDomain: process.env.REACT_APP_EMAIL_ASSETS_SERVER, // url of email image resource server
      paragraph1: intl.formatMessage({ id: 'email.paragraph1' }), // thank you
      paragraph2: intl.formatMessage({ id: 'email.paragraph2' }), // sharable link of the result page
      paragraph3: intl.formatMessage({ id: 'email.paragraph3' }), // download pdf link from cognex
      paragraph4: intl.formatMessage({ id: 'email.paragraph4' }), // contact cognex
      subject: intl.formatMessage({ id: 'email.subject' }),
      url: window.location.origin + window.location.pathname + '?id=' + userId.current,
      contactUrl: "mailto:" + localeInfo.email_contact_link,
    }

    toPost['product'] = {
      name: "Cognex ROI tool" // to display at the email title
    }

    console.log("to posts:");
    console.log(toPost);
    setPendingPOST(true)
    return axios.post(process.env.REACT_APP_SCRIPTS_SERVER + "/calls/process/index.php", toPost)
      .then((response) => {
        console.log(response);
        setPendingPOST(false)
        return response
      })
      .catch((error) => {
        if (error.message === 'Network Error') {
          setShowNID(true)
        }
        setPendingPOST(false)
        throw new Error(error)
      });
    // setSubmitForm(false)
  }
  const resetStates = () => {
    if (supportedLang[params.lang])
      setCurCurrency(langRef.current[params.lang].currency)
    setZoneId('')
    setApplicationId('')
  }

  useEffect(() => {
    window.addEventListener('online', handleConnection);
    window.addEventListener('offline', handleConnection);
    return () => {
      window.removeEventListener('online', handleConnection);
      window.removeEventListener('offline', handleConnection);
    }
  })


  const handleConnection = (e) => {
    if (e.type === "online") setIfOffline(false);
    if (e.type === "offline") setIfOffline(true);
  }

  //If the routing is not supproted, return 404 page
  if (!supportedLang[params.lang]) return <ErrorPage />;

  return (

    <GlobalStore.Provider
      value={{
        facilityId: facilityId,
        zoneId: zoneId,
        setZoneId: setZoneId,
        applicationId: applicationId,
        setApplicationId: setApplicationId,
        userId: userId,
        applicationsRef: applicationsRef,
        zonesRef: zonesRef,
        solutionsRef: solutionsRef,
        curCurrency: curCurrency,
        currenciesRef: currenciesRef,
        langRef: langRef,
        config: config,
        getZoneIdByPathname: getZoneIdByPathname,
        getApplicationIdByPathname: getApplicationIdByPathname,
        setCurPageId: setCurPageId,
        configData: configData,
        setConfigData: setConfigData,
        formData: formData,
        setFormData: setFormData,
        regionCode: regionCode,
        postData: postData,
        setRegionCode: setRegionCode,
        showNID: showNID,
        setShowNID: setShowNID,
        pendingPOST: pendingPOST,
        ROIAppFormSubmitted: ROIAppFormSubmitted,
        setROIAppFormSubmitted: setROIAppFormSubmitted,
        NonROIAppFormSubmitted: NonROIAppFormSubmitted,
        setNonROIAppFormSubmitted: setNonROIAppFormSubmitted,
        ifOffline
      }}>
      <div className="App" onClick={() => { setShowLangSelector(false) }}>
        {
          config.enablePWAUpdateRemainder && <UpdateRemainder />
        }

        {
          deviceType === 'mobile' && <UnfitRemainder />
        }

        <div ref={headerRef}>
          <TheHeader curPageId={curPageId} pages={pages} hideLangSwitcher={config.hideLangSwitcher} showLangSelector={showLangSelector} setCurCurrency={setCurCurrency} setShowLangSelector={setShowLangSelector} />
        </div>

        <div className={["app-main"].join(' ')}
          style={['application-view'].includes(levelTwoPage) ? { transitionDuration: '0s' } : {}}
        >
          <Switch>

            <Route path='/:lang' exact render={() => {
              return <TheHome resetStates={resetStates} thisPage={pages[0]} nextPage={pages[1]} />
            }
            } />
            <Route path={[
              '/:lang/' + pages[1].pathname,
              '/:lang/:zone',
              '/:lang/:zone/:application',
            ]} exact render={() => {
              return <FacilityMap thisPage={pages[1]} prevPage={pages[0]} nextPage={pages[2]} autoNavTo={config.autoNavTo} lockMapSidebar={config.lockMapSidebar}></FacilityMap>
            }} />

            <Route path={'/:lang/:zone/:application/' + pages[2].pathname} exact render={() => {
              return <ApplicationView exact thisPage={pages[2]} prevPage={pages[1]} />
            }} />

            <Route render={() => {
              return <ErrorPage />
            }} />

          </Switch>
        </div>
      </div>
    </GlobalStore.Provider>

  );
}

export default App;