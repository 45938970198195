
import { useState, useContext } from 'react';
import classes from './EmailShare.module.scss';
import TheEmail from './TheEmail/TheEmail'
import GlobalStore from '../../contexts/GlobalStore';
import axios from 'axios';
import { useIntl } from 'react-intl';
import useLocaleInfo from '../../Hooks/useLocaleInfo';
function EmailShare(props) {
  const store = useContext(GlobalStore)
  const localeInfo = useLocaleInfo(store.regionCode)
  const intl = useIntl()
  const [showEmailSender, setShowEmailSender] = useState(false)
  const [emailAddresses, setEmailAddresses] = useState([])
  const addToEmailList = (email) => {
    const newList = [...emailAddresses]
    newList.push(email + '')
    setEmailAddresses(newList)
  }

  const showEmailSenderHandler = () => {
    setShowEmailSender(!showEmailSender);
  }


  const submitEmail = (noteFromInput) => {
    const toPost = {
      userId: store.userId.current,
      addresses: emailAddresses,
      note: noteFromInput,
      configData: store.configData,
      formData: store.formData,
      contactUrl: "mailto:" + localeInfo.email_contact_link,
      emailDomain: process.env.REACT_APP_EMAIL_ASSETS_SERVER, // url of email image resource server
      paragraph1: intl.formatMessage({ id: 'email.share.paragraph1' }), // thank you
      paragraph2: intl.formatMessage({ id: 'email.share.paragraph2' }), // sharable link of the result page
      paragraph3: intl.formatMessage({ id: 'email.share.paragraph3' }), // download pdf link from cognex
      paragraph4: intl.formatMessage({ id: 'email.share.paragraph4' }), // contact cognex
      // pdf: "",
      subject1: intl.formatMessage({ id: 'email.share.subject1' }),
      subject2: intl.formatMessage({ id: 'email.share.subject2' }),
      product: intl.formatMessage({ id: 'email.share.product' }),
      subtitle: intl.formatMessage({ id: 'email.share.hello' }),
      url: window.location.origin + window.location.pathname + '?id=' + store.userId.current,
    }
    console.log(toPost);
    axios.post(process.env.REACT_APP_SCRIPTS_SERVER + "/calls/share/index.php", toPost)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const removeAddr = (email) => {
    const newList = emailAddresses.filter(entry => entry !== email)
    setEmailAddresses(newList)
  }

  const validateEmail = (mail) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (re.test(mail)) {
      return (true)
    }
    return (false)
  }

  return (
    <div className={classes.main}>
      <button className={['app-button-style-1', 'gtm-results-email'].join(' ')} onClick={showEmailSenderHandler}>
        {intl.formatMessage({ id: 'thanks.share.email.h1' })}
      </button>
      <div className={showEmailSender ? '' : classes['display-none']}>
        <TheEmail showEmailSenderHandler={showEmailSenderHandler}
          addToEmailList={addToEmailList}
          emailAddresses={emailAddresses}
          submitEmail={submitEmail}
          removeAddr={removeAddr}
          validateEmail={validateEmail}
        ></TheEmail>
      </div>
    </div>
  );
}

export default EmailShare;
