
import classes from './MapControlPanel.module.scss';
import { useContext } from 'react';
import plusImg from '../../assets/images/plus.png'
import GlobalStore from '../../contexts/GlobalStore';
import minusImg from '../../assets/images/minus.png'
import strokeImg from '../../assets/images/stroke.png'
import iImg from '../../assets/images/i.png'
import { levelTwoPageSwitcher } from '../../functions/routerFunctions';
import { useHistory } from 'react-router';

function MapControlPanel(props) {
  const store = useContext(GlobalStore)
  const history = useHistory()
  return (
    <div className={classes.main}>
      <div className={'app-hidden'}></div>
      <div className={classes['button']}
        onClick={() => {
          props.setScaleHelper(props.scale + 0.2)
        }}>
        <img src={plusImg} alt='plus'></img>
      </div>
      <div className={'app-hidden'}></div>


      <div className={classes['button']}
        onClick={() => {
          if (store.zoneId)
            props.resetAll()
          else
            levelTwoPageSwitcher(history.push, "/")
        }}>
        <img src={strokeImg} alt='stroke'></img>
      </div>
      <div className={'app-hidden'}></div>
      <div className={[classes['button'], classes['inactive']].join(' ')}>
        <img src={iImg} alt='i'></img>
      </div>


      <div className={'app-hidden'}></div>
      <div className={classes['button']}
        onClick={() => {
          props.setScaleHelper(props.scale - 0.2)
        }}>
        <img src={minusImg} alt='minus'></img>
      </div>
      <div className={'app-hidden'}></div>
    </div>
  );
}

export default MapControlPanel;
