
import classes from './ResultTable.module.scss';
import React, { useContext } from 'react';
import UnitTable from './UnitTable/UnitTable';
import GlobalStore from '../../contexts/GlobalStore';
import { useIntl } from 'react-intl';


function ResultTable(props) {
  // those two states are for parallelly uniform the title row height. So just set to empty if not needed
  const setTitleHeightsWithRowIndex = props.setTitleHeightsWithRowIndex ? props.setTitleHeightsWithRowIndex : () => { };
  const titleRowHeights = props.titleRowHeights ? props.titleRowHeights : []
  const intl = useIntl();
  const { tables, tablesH, applicationId, solutionId, index, full, recommendTabLocation } = props
  const hideLeft = index !== 0;
  const store = useContext(GlobalStore)
  const solutionInfo = store.solutionsRef.current[solutionId]
  const solutionName = intl.formatMessage({ id: solutionId + ".name" }, { br: <br /> });
  const imgPath = "/assets/images/solution_icons/" + solutionInfo.image;

  const getStaticInfoUnitTables = () => {


    const tablesObj = solutionInfo.tables
    const res = []
    for (let tableNameKey in tablesObj) {
      res.push(getStaticInfoUnitTable(tablesObj[tableNameKey]));
    }
    return res;
  }

  // helper function called by getStaticInfoUnitTables
  const getStaticInfoUnitTable = (tableObj) => {
    const tableName = tableObj.name
    const unitTableMeta = {}
    const rowsObj = tableObj.rows
    const titleRowMeta = tableObj.titleRowMeta
    if (!rowsObj) return null
    unitTableMeta['id'] = tableName;
    unitTableMeta['name'] = tableName;
    unitTableMeta['columns'] = [titleRowMeta["column-title"], titleRowMeta["column-title"]];
    unitTableMeta['columns-style-merge'] = titleRowMeta["style-merge"];
    const rows = {}

    for (let key of Object.keys(rowsObj)) {
      if (!rowsObj[key]) continue;
      // const uiText = intl.formatMessage({ id: "solution.info." + key });
      const uiText = rowsObj[key].name;
      rows[uiText] = {
        c1: null,
        c2: rowsObj[key].text,
        cross: true,
        title: uiText,
        // information: undefined,
        // rowId: "J11",
        suffix: "",
        "style-merge": rowsObj[key]['style-merge'],
        url: rowsObj[key]['url'],
        order: rowsObj[key].order,
        // title: "Estimated Productivity Improvem"
      }
    }

    if (Object.keys(rows).length === 0) return null;
    unitTableMeta['rows'] = rows;
    return unitTableMeta;
  }

  const getStyleObject = (recommendTabLocation) => {
    const res = {}
    if (recommendTabLocation === "LEFT") {
      res.borderTopLeftRadius = "10px";
    } else if (recommendTabLocation === "RIGHT") {
      res.borderTopRightRadius = "10px";
    } else if (recommendTabLocation === "SINGLE") {
      res.borderTopRightRadius = "10px";
      res.borderTopLeftRadius = "10px";
    }

    return res
  }

  const commendTabStyle = getStyleObject(recommendTabLocation)


  const staticUnitTableMeta = getStaticInfoUnitTables();
  const orgTablesList = Object.values(tables);
  const orgTablesListH = Object.values(tablesH);
  const UnitTableArray = []
  const UnitTableHArray = []
  for (let i = 0; i < orgTablesList.length; i++) {
    UnitTableArray.push(orgTablesList[i])
    UnitTableHArray.push(orgTablesListH[i])
    if (i === 0 && staticUnitTableMeta) {
      UnitTableArray.push(...staticUnitTableMeta)
      UnitTableHArray.push(...staticUnitTableMeta)
    }
  }


  return (
    <React.Fragment key={imgPath}>
      <div className={[classes['res-table'], hideLeft ? classes.single : ''].join(' ')}
        style={{
          "--title-row-height": `${80}px`,
          "--normal-row-height": `${80}px`,
        }}>
        <div className={classes['res-table-title']}>
          <h3 className={[hideLeft ? 'app-display-none' : ''].join(' ')}>
            {applicationId === 'application_06' ? "Tunnel Solutions" : "Solutions"}
          </h3>
          <a className={[
            classes['res-table-title-product'],
            solutionInfo.link_url ? "" : classes['no-link'],
            solutionInfo.image ? "" : classes['no-image']
          ].join(' ')}
            href={solutionInfo.link_url}
            target="_blank" rel="noreferrer"
          >
            {
              solutionInfo.image &&
              <img src={imgPath} alt={
                intl.formatMessage({ id: solutionId + ".image_alt" }, { br: <br /> }) ?
                  intl.formatMessage({ id: solutionId + ".image_alt" }, { br: <br /> })
                  : intl.formatMessage({ id: solutionId + ".name" }, { br: <br /> })} />
            }
            <h3>{solutionName}</h3>
            {
              recommendTabLocation &&
              <div className={classes['res-table-title-product-recommend-tab']} style={commendTabStyle}>Recommended Solution</div>
            }
          </a>
        </div>
        {UnitTableArray.map(
          (table, index) => {
            const tableH = UnitTableHArray[index]
            if (!full && index > 0) return null
            return <React.Fragment key={table.id}><UnitTable
              setMax={(max) => {
                const rowIndex = index;
                setTitleHeightsWithRowIndex(max, rowIndex)
              }}
              titleRowHeight={titleRowHeights[index]}
              table={table} tableH={tableH} hideLeft={hideLeft}></UnitTable></React.Fragment>
          }
        )}
      </div>
    </React.Fragment>
  )
}

export default ResultTable;
