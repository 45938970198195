export function levelTwoPageSwitcher(
    setUrl: Function,
    levelTwoPageName: string,
    addedQueryParams?: { [key: string]: string }
) {
    const paths = window.location.pathname.split("/");
    paths[2] = levelTwoPageName;
    const newPathname = paths.reduce(
        (previousValue, currentValue, currentIndex) => {
            if (!currentValue || currentValue === "/" || currentIndex > 2)
                return previousValue;
            else return previousValue + "/" + currentValue;
        },
        ""
    );

    let originalQueryObj: { [key: string]: string } = {};
    let oriQueries = window.location.search;
    if (oriQueries && oriQueries.split("?")[1]) {
        oriQueries = oriQueries.split("?")[1];
        oriQueries.split("&").forEach((entry) => {
            const pair = entry.split("=");
            originalQueryObj[pair[0]] = pair[1];
        });
    }

    const newQueryObj = { ...originalQueryObj, ...addedQueryParams };
    let newQueries = "";
    if (newQueryObj) {
        for (let key in newQueryObj) {
            newQueries += "&" + key + "=" + newQueryObj[key];
        }
    }
    if (newQueries) newQueries = "?" + newQueries.slice(1);

    const newUrl = newPathname + newQueries;
    
    setUrl(newUrl);
}
