
import classes from './AccurateSortGraph.module.scss';
import { useState, useEffect } from 'react';
import GlobalStore from '../../../contexts/GlobalStore';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, ResponsiveContainer, Tooltip } from 'recharts';
import { calculateMathFormula, formulaConvertor, resCurrencyFormatter } from '../../../functions/index'


/**
 * 
 * @param {
 * "Product Accurate Sort": "J2*J3*J5*J6",
 * "Solution Accurate Sort": "J2*J3*J5*J6*(J8+1)",
 * "domain": Y axis range,
 * "precision": X label
 * } props.config
 * 
 * @returns 
 */

function AccurateSortGraph(props) {
  const store = useContext(GlobalStore);
  const { solutionsResults, baseSettings } = props;
  const [data, setData] = useState([]);
  const intl = useIntl()

  const config = props.config ? props.config : {
    "Product Accurate Sort": "J2*J3*J5*J6",
    "Solution Accurate Sort": "J2*J3*J5*J6*(J8+1)",
  }

  const Ymin = props.config.Ymin ? props.config.Ymin : 0
  let domain = [0, 'auto']
  if (Ymin === "dataMin") {
    domain = [(dataMin) => dataMin * 0.95, 'auto']
  }

  const precision = props.config.precision ? props.config.precision : 'number_k'

  const colors = ["#C9C9C9", "#FFF200", "#FCB040", "#016AC0", "#003B6C"]

  const updateData = () => {
    const newData = []
    const settings = solutionsResults[0].advSettings ? { ...baseSettings, ...solutionsResults[0].advSettings } : baseSettings

    solutionsResults.forEach(solutionsResult => {
      newData.push({
        name: store.solutionsRef.current[solutionsResult.solutionId].accurate_sort_graph_name ?
          intl.formatMessage({ id: solutionsResult.solutionId + ".accurate_sort_graph_name" }, { br: <br /> })
          :
          intl.formatMessage({ id: solutionsResult.solutionId + ".name" }, { br: <br /> }),
        value: Math.ceil(calculateMathFormula(formulaConvertor(config["Solution Accurate Sort"], { ...baseSettings, ...solutionsResult.advSettings })))
      })
    })
    newData.push({
      name: "IS2800",
      value: calculateMathFormula(formulaConvertor(config["Product Accurate Sort"], settings))
    })
    setData(newData)
  }
  useEffect(() => {
    updateData()
    // eslint-disable-next-line
  }, [solutionsResults])

  const CustomTooltip = (props) => {
    const { active, payload, label } = props;
    if (active && payload && payload.length) {
      const value = payload[0].value
      const display = resCurrencyFormatter(value, "number")
      return (
        <div className={[classes["custom-tooltip"], "app-box-shadow"].join(" ")}>
          <p>{label}</p>
          <p className={classes["label"]}>{display + " sorts per day"}</p>
        </div>
      );
    }

    return null;
  };
  return (
    <div className={classes["graph-box"]}>
      <h3>Accurate Sorts per Day</h3>
      <div className={classes['main']}>
        <h4>Sorts</h4>
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <Tooltip
              cursor={false}
              content={<CustomTooltip />}
            />
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name"
              tick={<CustomizedAxisTick />}
              interval={0}
              height={50}
            >
            </XAxis>
            <YAxis
              tickFormatter={(value) => {
                return resCurrencyFormatter(value, "number")
              }}
              domain={domain}
            >
            </YAxis>
            <Bar dataKey="value"
              radius={[10, 10, 0, 0]}
              label={{
                position: 'top',
                formatter: (value) => resCurrencyFormatter(value, precision)
              }}
            >
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}

export default AccurateSortGraph;


const CustomizedAxisTick = (props) => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={0} y={0} dy={16} fill="#666" className={classes['x-label']}>
        <tspan textAnchor="middle" x="0" dy="10">{payload.value}</tspan>
      </text>
    </g>
  );
}
