import classes from './TheResults.module.scss';
import GlobalStore from '../../contexts/GlobalStore';
import { useContext } from 'react';

// eslint-disable-next-line
import { useState, useEffect, useRef } from 'react';

import { FormattedMessage } from 'react-intl';
import EmailShare from '../../components/EmailShare/EmailShare';
import ErrorBoundary from '../../ErrorBoundary';
import ROIGraph from '../../components/Graphs/ROIGraph/ROIGraph';
import TableGroup from '../../components/TableGroup/TableGroup';
import TheBarChart from '../Graphs/AccurateSortGraph/AccurateSortGraph';
import TheFooter from '../../components/TheFooter/TheFooter';
import TheResources from '../../components/TheResources/TheResources';
import ThroughputGraph from '../../components/Graphs/ThroughputGraph/ThroughputGraph';
import topArrow from '../../assets/Back-to-top-arrow.png'
import useGoogleAdParams from '../../Hooks/useGoogleAdParams';
import useLocaleInfo from '../../Hooks/useLocaleInfo';
import { useIntl } from 'react-intl';

function TheResults(props) {
	const applicationsRef = useRef(require("../../configs/applications.json"));
	const GAParams = useGoogleAdParams();
	const intl = useIntl();
	const store = useContext(GlobalStore);
	let campaignUri = "";

	const localeInfo = useLocaleInfo(store.regionCode);

	const {
		applicationId,
		baseSettings,
		solutionsResults
	} = props;

	let graphAmount = 0;
	let theTitle = '';

	if (applicationsRef.current[applicationId] && applicationsRef.current[applicationId].ROIGraph) {
		graphAmount++;
	}

	if (applicationsRef.current[applicationId] && applicationsRef.current[applicationId].throughputGraph) {
		graphAmount++;
	}

	if (applicationsRef.current[applicationId]) {
		theTitle = applicationsRef.current[applicationId].name_on_report_page ? intl.formatMessage({ id: applicationId + ".name_on_report_page" }, { br: <br /> }) : intl.formatMessage({ id: applicationId + ".name" }, { br: <br /> }) + " " + intl.formatMessage({ id: "results.ROIReport" }, { br: <br /> });
	}

	if (GAParams.cm_campid != null && GAParams.cm_campid != "") {
		campaignUri += "?cm_campid=" + GAParams.cm_campid;
	} else {
		campaignUri += "?cm_campid=" + process.env.REACT_APP_REQUEST_DEMO_CODE;
	}

	if (GAParams.utm_campaign != null && GAParams.utm_campaign != "") {
		campaignUri += '&utm_campaign=' + GAParams.utm_campaign;
	}

	if (GAParams.utm_content != null && GAParams.utm_content != "") {
		campaignUri += '&utm_content=' + GAParams.utm_content;
	}

	if (GAParams.utm_id != null && GAParams.utm_id != "") {
		campaignUri += '&utm_id=' + GAParams.utm_id;
	}

	if (GAParams.utm_medium != null && GAParams.utm_medium != "") {
		campaignUri += '&utm_medium=' + GAParams.utm_medium;
	}

	if (GAParams.utm_source != null && GAParams.utm_source != "") {
		campaignUri += '&utm_source=' + GAParams.utm_source;
	}

	if (GAParams.utm_term != null && GAParams.utm_term != "") {
		campaignUri += '&utm_term=' + GAParams.utm_term;
	}

	return (
		<div className={classes.main}>
			<div className={[classes['control-btns'], "no-print", classes['control-btns-padding']].join(" ")}>
				<button className={['app-button-style-blue-fill', 'gtm-results-request-demo'].join(' ')}>
					<a href={localeInfo.request_demo_link + campaignUri} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "results.requestDemo" }, { br: <br /> })}</a>
				</button>

				<EmailShare></EmailShare>

				<button className={['app-button-style-1', 'gtm-results-print'].join(' ')} onClick={() => { window.print() }} > <FormattedMessage id='app.print' /></button>
			</div>

			<div className={[classes.header].join(" ")}>
				<h1>{theTitle}</h1>

				<button onClick={() => props.setShowLeft(true)} className={"app-button-green"}>{intl.formatMessage({ id: "results.updateROI" }, { br: <br /> })}</button>
			</div>

			<div className={classes.body}>
				<div className={[classes['graph-container']].join(' ')}>
					<div className={[classes['graph']].join(' ')} style={{ "--graph-amount": graphAmount }}>
						{
							solutionsResults.length > 0 &&

							<ErrorBoundary>
								{
									applicationId && store.applicationsRef.current[applicationId].ROIGraph && <ROIGraph solutionsResults={solutionsResults} config={store.applicationsRef.current[applicationId].ROIGraph} unit={"month"} />
								}

								{
									applicationId && store.applicationsRef.current[applicationId].throughputGraph && <ThroughputGraph baseSettings={baseSettings} solutionsResults={solutionsResults} config={store.applicationsRef.current[applicationId].throughputGraph} />
								}

								{
									applicationId && store.applicationsRef.current[applicationId].theBarChart && <TheBarChart baseSettings={baseSettings} solutionsResults={solutionsResults} config={store.applicationsRef.current[applicationId].theBarChart} />
								}
							</ErrorBoundary>
						}
					</div>
				</div>

				<TableGroup applicationId={applicationId} baseSettings={baseSettings} solutionsResults={solutionsResults} full={true} />
				<TheResources applicationId={applicationId} amountPerPage={undefined} />

				<div className={classes['bottom-buttons']}>
					<div className={[classes['control-btns'], "no-print"].join(" ")}>
						<button className={['app-button-style-blue-fill', 'gtm-results-request-demo'].join(' ')}>
							<a href={localeInfo.request_demo_link + campaignUri} target="_blank" rel="noreferrer">{intl.formatMessage({ id: "results.requestDemo" }, { br: <br /> })}</a>
						</button>

						<EmailShare></EmailShare>

						<button className={['app-button-style-1', 'gtm-results-print'].join(' ')} onClick={() => { window.print() }} > <FormattedMessage id='app.print' /></button>
					</div>

					<button className={classes['button-to-top']} onClick={() => { window.scrollTo(0, 0) }}>
						<img src={topArrow} alt='arrow' />
					</button>
				</div>
			</div>

			<TheFooter />
		</div>
	);
}

export default TheResults;