

import classes from './InnerUserForm.module.scss';
import GlobalStore from '../../../contexts/GlobalStore';
// eslint-disable-next-line
import { useIntl, FormattedMessage } from 'react-intl';
import { useContext, useRef, useState, useEffect } from 'react';
import React from 'react';
import countryList from '../../../configs/regions/forForm/countries.json'
import stateList from '../../../configs/regions/forForm/states.json'
import PopupWrapper from '../../../components/PopupWrapper/PopupWrapper';
import useGoogleAdParams from '../../../Hooks/useGoogleAdParams';
import { useHistory } from 'react-router';

function InnerUserForm(props) {

  // eslint-disable-next-line
  const store = useContext(GlobalStore)
  const {
    // eslint-disable-next-line
    nextFlow,

  } = props
  const {
    setFormData,
    postData,
    setRegionCode,
    showNID,
    setShowNID,
    pendingPOST
  } = store
  const GAParams = useGoogleAdParams();
  // eslint-disable-next-line
  const history = useHistory()
  // eslint-disable-next-line
  const intl = useIntl()
  const contriesRef = useRef(countryList.sort((a, b) => { return a.cnx_countryId > b.cnx_countryId ? 1 : -1 }))
  const allStatesRef = useRef(stateList.sort((a, b) => { return a.cnx_stateprovinceId > b.cnx_stateprovinceId ? 1 : -1 }))
  const stateRef = useRef([]);
  const [formValid, setFormValid] = useState(true)
  const [checked, setChecked] = useState(false)
  const [checkBoxWarning, setCheckBoxWarning] = useState(false)
  const projectTimelineArray = [
    {
      id: '100000003',
      cnx_name: intl.formatMessage({ id: "userform.projectTimeline.yes6" }, { br: <br /> }),
    },
    {
      id: '100000000',
      cnx_name: intl.formatMessage({ id: "userform.projectTimeline.yes6to12" }, { br: <br /> }),
    },
    {
      id: '100000001',
      cnx_name: intl.formatMessage({ id: "userform.projectTimeline.yes12" }, { br: <br /> }),
    },
    {
      id: '100000002',
      cnx_name: intl.formatMessage({ id: "userform.projectTimeline.no" }, { br: <br /> }),
    },
  ]
  const [inputs, setInputs] = useState(
    {
      formFirstName: {
        valid: true,  // the form is valid only if all entry's valid is true
        required: true, // if not required, empty string will be valid
        hide: false, // hide at UI
        type: 'input', // UI type
        id: "formFirstName",
        text: intl.formatMessage({ id: "userform.firstName" }, { br: <br /> }),
        value: ""
      },
      formLastName: {
        valid: true,
        required: true,
        hide: false,
        type: 'input',
        id: "formLastName",
        text: intl.formatMessage({ id: "userform.lastName" }, { br: <br /> }),
        value: ""
      },
      emailAddress: {
        valid: true,
        required: true,
        hide: false,
        type: 'input',
        id: "formEmail",
        text: intl.formatMessage({ id: "userform.emailAddr" }, { br: <br /> }),
        value: ""
      },
      formCompany: {
        valid: true,
        required: true,
        hide: false,
        type: 'input',
        id: "formCompany",
        text: intl.formatMessage({ id: "userform.company" }, { br: <br /> }),
        value: ""
      },
      formCountry: {
        valid: true,
        required: true,
        hide: false,
        type: 'select',
        id: "formCountry",
        text: intl.formatMessage({ id: "userform.countryRegion" }, { br: <br /> }),
        value: ""
      },
      formStateProvince: {
        valid: true,
        required: true,
        hide: false,
        type: 'select',
        id: "formStateProvince",
        text: intl.formatMessage({ id: "userform.stateProvince" }, { br: <br /> }),
        value: "default"
      },
    }
  )



  const submitHandler = (e) => {
    e.preventDefault()
    if (pendingPOST) return;
    const formValid = validationCheck()
    if (formValid) {
      const UserformInputs = submitEloqua()
      UserformInputs.ifROI = true
      setFormData(UserformInputs)

      // From the client: I should only receive an email after I enter my base settings.
      // postData(UserformInputs).then(() => {
      //   console.log("POST to server success!");
      //   nextFlow();
      // })

      // directly go to next flow without checking response from server, since the form will be submitted after user input base settings
      nextFlow();
    }
  }

  useEffect(() => { /* Set the country to United States by default */
    const newInputs = { ...inputs }
    newInputs.formCountry.value = "United States";
    stateRef.current = allStatesRef.current.filter(entry => entry.cnx_Country === newInputs.formCountry.value);
    setChecked(true)
    setInputs(newInputs)
    setRegionCode('en-us')
    // eslint-disable-next-line
  }, [])


  const singleCheck = (inputEntry) => {
    const value = inputEntry.value
    const id = inputEntry.id
    if (value === '' || value === 'default') {
      if (inputEntry.required)
        inputEntry.valid = false
      else
        inputEntry.valid = true
      return
    }

    if (id === "formFirstName" || id === "formLastName") {
      inputEntry.valid = !/[^A-Za-z]+/g.test(value)
    }
    else if (id === 'formEmail') {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      inputEntry.valid = re.test(String(value).toLowerCase());
    }
    else if (id === 'formZipPostal') {
      inputEntry.valid = !/[^0-9]+/g.test(value)
    }
    else if (id === 'formPhone') {
      let validation = !/[^0-9]+/g.test(value)
      if (inputs.formCountry.value === "1E417370-937E-E111-BD3E-005056B20035")
        validation = validation && value.length === 10
      inputEntry.valid = validation
    }
    else
      inputEntry.valid = true
  }
  const validationCheck = () => {
    const newInputs = { ...inputs }
    let valid = true
    for (let key of Object.keys(newInputs)) {
      singleCheck(newInputs[key])
      valid = valid && newInputs[key].valid
    }
    setFormValid(valid)
    if (!valid) setInputs(newInputs)


    // Make this optional
    // Check if terms agreed
    // if (!checked) setCheckBoxWarning(true)
    // return valid && checked

    return valid
  }
  const submitEloqua = () => {
    // create the form object
    // eslint-disable-next-line
    let eloquaForm = new Object();
    eloquaForm.address = {
      id: process.env.REACT_APP_ELOQUA_ADDRESS,
      type: "FormField",
      // value: inputs.formAddress.value
      value: "N/A"
    }

    eloquaForm.city = {
      id: process.env.REACT_APP_ELOQUA_CITY,
      type: "FormField",
      // value: inputs.formCity.value
      value: "N/A"
    }

    eloquaForm.company = {
      id: process.env.REACT_APP_ELOQUA_COMPANY,
      type: "FormField",
      value: inputs.formCompany.value
      // value: "N/A"
    }

    eloquaForm.country = {
      id: process.env.REACT_APP_ELOQUA_COUNTRY,
      type: "FormField",
      value: inputs.formCountry.value
    }

    eloquaForm.division = {
      id: process.env.REACT_APP_ELOQUA_DIVISION,
      type: "FormField",
      value: "N/A"
    }

    eloquaForm.email = {
      id: process.env.REACT_APP_ELOQUA_EMAIL,
      type: "FormField",
      value: inputs.emailAddress.value
    }

    eloquaForm.environment = {
      value: process.env.REACT_APP_ELOQUA
    }

    eloquaForm.firstName = {
      id: process.env.REACT_APP_ELOQUA_FIRSTNAME,
      type: "FormField",
      value: inputs.formFirstName.value
    }

    eloquaForm.lastName = {
      id: process.env.REACT_APP_ELOQUA_LASTNAME,
      type: "FormField",
      value: inputs.formLastName.value
    }

    eloquaForm.phone = {
      id: process.env.REACT_APP_ELOQUA_PHONE,
      type: "FormField",
      // value: inputs.formPhone.value
      value: "N/A"
    }


    eloquaForm.productPricing = {
      id: process.env.REACT_APP_ELOQUA_PRODUCTPRICING,
      type: "FormField",
      value: "N/A"
    }

    eloquaForm.projectTimeline = {
      id: process.env.REACT_APP_ELOQUA_PROJECTTIMELINE,
      type: "FormField",
      // value: inputs.formProjectTimeline.value
      value: "N/A"
    }

    eloquaForm.prospectNotes = {
      id: process.env.REACT_APP_ELOQUA_PROSPECTNOTES,
      type: "FormField",
      // value: inputs.formProspectNotes.value
      value: "N/A"
    }

    eloquaForm.sourceCampaign = {
      id: process.env.REACT_APP_ELOQUA_SOURCECAMPAIGN,
      type: "FormField",
      value: GAParams.utm_id ? GAParams.utm_id : process.env.REACT_APP_CAMPAIGN_CODE
    }

    eloquaForm.stateProvince = {
      id: process.env.REACT_APP_ELOQUA_STATEPROVINCE,
      type: "FormField",
      value: inputs.formStateProvince.value
    }

    eloquaForm.subject = {
      id: process.env.REACT_APP_ELOQUA_SUBJECT,
      type: "FormField",
      value: "ROI Calculator"
    }

    eloquaForm.zipPostal = {
      id: process.env.REACT_APP_ELOQUA_ZIPPOSTAL,
      type: "FormField",
      // value: inputs.formZipPostal.value
      value: "N/A"
    }

    eloquaForm.optIn = {
      id: process.env.REACT_APP_ELOQUA_OPTIN,
      type: "FormField",
      // value: inputs.formZipPostal.value
      value: checked ? 1 : 0
    }

    eloquaForm.industry = {
      id: process.env.REACT_APP_ELOQUA_INDUSTRY,
      type: "FormField",
      value: "logistics"
    }

    return eloquaForm;
  }


  const inputBlock = (entry, index) => {
    if (entry.hide) return null
    const newInputs = { ...inputs }
    return (
      <div className={['app-input-group',
        entry.valid ? null : "app-input-group-invalid"
      ].join(" ")}>
        <p>
          {entry.text}
          {
            entry.required ? <span style={{ color: 'red' }}>{' *'}</span> : null
          }
        </p>
        <input
          value={entry.value}
          onChange={(e) => {
            entry.value = e.target.value
            entry.valid = true
            setInputs(newInputs)
          }}></input>
      </div>
    )
  }

  const textareaBlock = (entry, index) => {
    if (entry.hide) return null
    const newInputs = { ...inputs }
    return (
      <div className={['app-input-group',
        classes["right-form-body-z"],
        classes["textarea-block"],
        entry.valid ? null : "app-input-group-invalid"
      ].join(" ")}>
        <p>
          {entry.text}
          {
            entry.required ? <span style={{ color: 'red' }}>{' *'}</span> : null
          }
        </p>
        <textarea
          rows='4'
          value={entry.value}
          onChange={(e) => {
            entry.value = e.target.value
            entry.valid = true
            setInputs(newInputs)
          }}></textarea>
      </div>
    )
  }

  const selectBlock = (entry, index) => {
    if (entry.hide) return null
    const newInputs = { ...inputs }
    let optionData = []
    let idColumnName = 'id'
    if (entry.id === 'formCountry') {
      optionData = contriesRef.current
      idColumnName = 'cnx_countryId'
    }
    else if (entry.id === 'formStateProvince') {
      optionData = stateRef.current
      idColumnName = 'cnx_stateprovinceId'
    }
    else if (entry.id === 'formProjectTimeline') {
      optionData = projectTimelineArray
      idColumnName = 'cnx_name'
    }
    let displayTextColumnName = idColumnName;


    return (
      <div className={['app-select-group',
        entry.valid ? null : "app-select-group-invalid"
      ].join(" ")}>
        <p>
          {entry.text}
          {
            entry.required ? <span style={{ color: 'red' }}>{' *'}</span> : null
          }
        </p>
        <select value={inputs[entry.id].value}
          onChange={(e) => {
            entry.value = e.target.value
            entry.valid = true
            if (entry.id === 'formCountry') {
              const newStatesOptionData = allStatesRef.current.filter(entry => entry.cnx_Country === e.target.value);
              stateRef.current = newStatesOptionData;
              newInputs['formStateProvince'].value = 'default';
              if (newStatesOptionData.length > 0) {
                newInputs['formStateProvince'].hide = false;
                newInputs['formStateProvince'].required = true;
              } else {
                newInputs['formStateProvince'].hide = true;
                newInputs['formStateProvince'].required = false;
              }

              /* Set region code when user selected country on the form */
              let regionCode = ''
              if (e.target.value && e.target.value !== 'default') {
                for (let data of optionData) {
                  if (data[idColumnName] === e.target.value) {
                    regionCode = data["region_code"]
                    console.log(data);
                    setChecked(data.term_checked ? true : false)
                    break;
                  }

                }
              }
              setRegionCode(regionCode)
            }
            setInputs(newInputs)
          }}>
          <option value="default" disabled></option>
          {
            optionData.map((option) => {
              return <option key={option[idColumnName]} value={option[idColumnName]}>{option[displayTextColumnName]}</option>
            })
          }
        </select>
      </div>
    )
  }

  return (
    <div className={classes.main}>
      <PopupWrapper
        show={showNID}
        setShow={setShowNID}
        wrapperStyle={{ width: "100%", height: "100%" }}
      >
        <div className={classes['no-internet-dialog']}>
          <h2>{intl.formatMessage({ id: "app.noInternet" }, { br: <br /> })}</h2>
          <div>
            <button className={"app-button-style-1"}
              onClick={submitHandler}
            >
              {pendingPOST ? "Loading..." : "Submit form again"}
            </button>
            <button className={"app-button-style-2"}
              onClick={props.nextFlow}
            >{intl.formatMessage({ id: "app.continue" }, { br: <br /> })}</button>
          </div>
        </div>
      </PopupWrapper>
      <div className={classes['title']}>
        <p>{intl.formatMessage({ id: "userform.nonROItitle" }, { br: <br /> })}</p>
      </div>
      <div className={classes.right}>
        <form className={classes['right-form']}>
          <div className={classes['right-form-body']}>
            {Object.values(inputs).map((entry, index) => {
              return (
                <React.Fragment key={entry.id}>
                  {
                    entry.type === 'input' ?
                      inputBlock(entry, index)
                      :
                      entry.type === 'select' ?
                        selectBlock(entry, index)
                        :
                        entry.type === 'textarea' ?
                          textareaBlock(entry, index)
                          : null
                  }
                </React.Fragment>
              )
            })}
          </div>
          {
            formValid ?
              null :
              <p className={classes['right-form-body-invalid-p']}>{intl.formatMessage({ id: "userform.invalid" }, { br: <br /> })}</p>
          }
          <div className={classes['footer']}>
            <div className={classes['first-box']}>
              <button type='submit' className={['app-button-style-1', 'gtm-form-submit-long', formValid ? null : classes['form-invalid'], pendingPOST ? classes['disable-button'] : null].join(' ')}
                onClick={submitHandler}>
                {
                  pendingPOST ?
                    intl.formatMessage({ id: "app.loading" }, { br: <br /> }) + "..."
                    :
                    intl.formatMessage({ id: "app.submit" }, { br: <br /> })
                }
              </button>
              <Checkbox
                label={intl.formatMessage({ id: "userform.optIn" }, { br: <br /> })}
                checked={checked}
                onChange={(e) => { setChecked((prev) => !prev) }}
                checkBoxWarning={checkBoxWarning}
                setCheckBoxWarning={setCheckBoxWarning}
              />
            </div>
            <p className={classes['second-box']}>{intl.formatMessage({ id: "userform.optIn.subtitle" }, { br: <br /> })}</p>
          </div>
        </form>
      </div>
    </div>
  );
}

export default InnerUserForm;


const Checkbox = ({ label, checked, onChange, checkBoxWarning, setCheckBoxWarning }) => {
  return (
    <label className={[classes['check-box'], checkBoxWarning ? classes['check-box-warning'] : null].join(' ')}>
      <input type="checkbox" checked={checked} onChange={() => { onChange(); setCheckBoxWarning(false) }} />
      <p>{label}</p>
    </label>
  );
};
