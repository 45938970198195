import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import MultiLangWrapper from './contexts/MultiLangWrapper';
import { BrowserRouter, Switch, Redirect, Route } from 'react-router-dom';
import localeInfoMap from './configs/regions/forLocale/regions.json';
import langsInfoRow from './configs/languages/langs.json'


// get language code by Navigator.language, also can be overwritten by input
const getInitLangId = () => {
  let lang = 'en';
  if (navigator && localeInfoMap[navigator.language.toLowerCase()]) {
    lang = localeInfoMap[navigator.language.toLowerCase()].lang_id
  }

  const langsInfo = langsInfoRow;
  if (!langsInfo[lang].available) {
    console.log(lang + " is not supported yet, backing to English");
    lang = "en";
  }

  //console.log("Language from browser setting:");
  //console.log(navigator.language);
  //console.log("Rendering language:");
  //console.log(lang);
  // default to en
  return lang;
}


ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <MultiLangWrapper>
        <Switch>
          <Redirect from='/' exact to={`/${getInitLangId()}` + window.location.search}  ></Redirect>
          <Route path='/:lang' component={App} />
        </Switch>
      </MultiLangWrapper>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);




serviceWorkerRegistration.register();
reportWebVitals();
