// eslint-disable-next-line
import classes from './AdvSettingInputEl.module.scss';
import { useRef } from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import { useContext } from 'react';
import Slider, { SliderTooltip } from 'rc-slider';
import { useIntl } from 'react-intl';
const { Handle } = Slider;


function AdvSettingInputEl(props) {
  const store = useContext(GlobalStore)
  const intl = useIntl()
  const { solutionObj, setSolutionObj } = props;
  const { advSettings, advSettingsH } = solutionObj;
  const setAdvSettings = (newAdvSettings) => {
    const newSolutionObj = { ...solutionObj }
    newSolutionObj.advSettings = newAdvSettings
    setSolutionObj(newSolutionObj)
  }
  const setAdvSettingsH = (newAdvSettingsH) => {
    const newSolutionObj = { ...solutionObj }
    newSolutionObj.advSettingsH = newAdvSettingsH
    setSolutionObj(newSolutionObj)
  }

  const initValueRef = useRef(advSettings.J8.value * 100);

  const handle = props => {
    const { value, dragging, index, ...restProps } = props;
    return (
      <SliderTooltip
        prefixCls="rc-slider-tooltip"
        overlay={`${value} %`}
        visible={dragging}
        placement="top"
        key={index}
      >
        <Handle value={value} {...restProps} />
      </SliderTooltip>
    );
  };

  return (
    <>
      <div>
        {/* <p>{store.solutionsRef.current[solutionObj.solutionId].name}</p> */}
        <p>{intl.formatMessage({ id: solutionObj.solutionId+".name" }, { br: <br /> })}</p>
        <Slider min={Math.max(initValueRef.current - 30, 0)} max={initValueRef.current + 30} value={advSettings.J8.value * 100} handle={handle} onChange={e => {
          const newAdvSettings = { ...advSettings }
          const newAdvSettingsH = { ...advSettingsH }
          newAdvSettings.J8.value = e / 100;
          newAdvSettingsH.J8.value = e / 100;
          setAdvSettings(newAdvSettings)
          setAdvSettingsH(newAdvSettingsH)
        }}
          trackStyle={{ backgroundColor: "#016AC0" }}
          handleStyle={{ borderColor: "#016AC0" }}
        />
      </div>
    </>
  );
}

export default AdvSettingInputEl;
