import React, { Component } from 'react';
import classes from './TheEmail.module.scss';
import { withRouter } from 'react-router-dom';
import { injectIntl } from 'react-intl';
import { FormattedMessage } from 'react-intl';
import { ReactComponent as Close } from '../../../assets/close-popup.svg';

class TheEmail extends Component {
	state = {
		emailInputBuffer: '',
		emailNoteBuffer: '',
		page: 0,
		validQuest: true,
		validEmailInput: true
	}
	componentDidMount() {
		// Add enter key listener for add-email btn
		// Get the input field
		var input = document.getElementById("addEmail");
		input.addEventListener("keyup", function (event) {
			if (event.key === 'Enter') {
				event.preventDefault();
				document.getElementById("addEmailBtn").click();
			}
		});
	}
	emailAddressInputHandler = (e) => {
		this.setState({
			emailInputBuffer: e.target.value
		})
	}
	emailNoteHandler = (e) => {
		this.setState({
			emailNoteBuffer: e.target.value
		})
	}

	emptyEmailAddressesCheck = () => {
		return this.props.emailAddresses.length !== 0
	}

	reset = () => {
		this.setState({
			emailInputBuffer: '',
			validQuest: true,
			validEmailInput: true
		})
	}



	render() {
		const body = this.state.page === 0 ?
			<React.Fragment>
				<h3 className={classes['email-title']}><FormattedMessage id={'thanks.share.email.h1'} defaultMessage='react-intl missing id' /></h3>
				<p className={classes['email-p']}>
					<FormattedMessage id={'thanks.share.email.p1'} defaultMessage='react-intl missing id' />
				</p>
				<div className={classes['email-input-container']}>
					<div>
						{
							this.props.emailAddresses.map(
								(email, index) => {
									return <div key={email + index} className={classes['email-address-container']}>
										<div className={classes['email-address-wrapper']}>
											<p>
												{email}
											</p>
											<div className={classes['email-address-remove']} onClick={() => this.props.removeAddr(email)}>
												<Close />
											</div>
										</div>
									</div>
								}
							)
						}
						<input type='email' id="addEmail" placeholder={this.props.intl.formatMessage({ id: "thanks.share.email.placeholder1" })} className={classes['email-address-input']} onChange={this.emailAddressInputHandler} value={this.state.emailInputBuffer}></input>
					</div>




				</div>
				<button id="addEmailBtn" className={[classes['email-add-btn'], this.state.emailInputBuffer ? "" : "app-none"].join(" ")} onClick={() => {
					if (this.props.validateEmail(this.state.emailInputBuffer)) {
						this.reset()
						this.props.addToEmailList(this.state.emailInputBuffer)
					} else {
						this.setState({
							validEmailInput: false
						})
					}
				}}><FormattedMessage id={'thanks.share.email.add'} defaultMessage='react-intl missing id' /></button>
				<textarea rows='3' type='text' placeholder={this.props.intl.formatMessage({ id: 'thanks.share.email.placeholder2' })} className={classes['email-note']} onChange={this.emailNoteHandler} value={this.state.emailNoteBuffer}></textarea>
				<div className={classes.warnings}>
					{
						this.state.validQuest ?
							null :
							<p>{this.props.intl.formatMessage({ id: "thanks.share.email.warning1" })}</p>
					}
					{
						this.state.validEmailInput ?
							null :
							<p>{this.props.intl.formatMessage({ id: "thanks.share.email.warning2" })}</p>
					}
				</div>


				<button className={[classes['email-btn'], "app-button-style-blue-fill"].join(' ')}
					onClick={
						() => {
							const validQuest = this.emptyEmailAddressesCheck()
							if (validQuest) {
								this.setState({ validQuest: true })
								this.setState({ page: 1 })
								this.props.submitEmail(this.state.emailNoteBuffer)
							} else {
								this.setState({ validQuest: false })
							}
						}
					}
				><FormattedMessage id={'thanks.share.email.submit'} defaultMessage='react-intl missing id' /></button>

				<button className={[classes['email-btn'], "app-button-style-1"].join(' ')}
					onClick={() => {
						this.props.showEmailSenderHandler()
						this.reset()
					}}
				><FormattedMessage id={'thanks.share.email.cancel'} defaultMessage='react-intl missing id' /></button>


			</React.Fragment>
			: <React.Fragment>
				<h3 className={classes['email-title-2']}><FormattedMessage id={'thanks.share.email.thanks'} defaultMessage='react-intl missing id' /></h3>
				<p className={classes['email-p-2']}> <FormattedMessage id={'thanks.share.email.p3'} defaultMessage='react-intl missing id' /> </p>
				<button className={[classes['email-btn'],  "app-button-style-1"].join(' ')}
					onClick={() => {
						this.setState({
							page: 0
						})
						this.props.showEmailSenderHandler()
						this.reset()
					}}
				><FormattedMessage id={'thanks.share.email.close'} defaultMessage='react-intl missing id' /> </button>
			</React.Fragment>
		return (
			<div className={[classes['dialog-main'], this.props.mainClassName].join(' ')} >
				<div className={[classes['dialog-body'], classes.bodywidth, classes['email-body']].join(' ')}>
					{body}
				</div>
			</div>
		);
	}

}

export default injectIntl(withRouter(TheEmail));