import classes from './TheHeader.module.scss';
import HeaderLogo from '../HeaderLogo/HeaderLogo';
import { useContext, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import React from 'react';
import GlobalStore from '../../contexts/GlobalStore';
import arrowWhite from '../../assets/arrow-white.svg'
import useLocaleInfo from '../../Hooks/useLocaleInfo';
import useGoogleAdParams from '../../Hooks/useGoogleAdParams';
import { useIntl } from 'react-intl';

function TheHeader(props) {
	const curPageId = props.curPageId;
	const facilityAndZoneRef = useRef(require('../../configs/facilityAndZone.json'));
	const GAParams = useGoogleAdParams();
	const hideLangSwitcher = props.hideLangSwitcher;
	const history = useHistory();
	const intl = useIntl();
	const location = useLocation();
	const store = useContext(GlobalStore);
	let campaignUri = "";

	const curLang = location.pathname.split('/')[1];
	const langRef = store.langRef;
	const localeInfo = useLocaleInfo(store.regionCode);

	const langOptions = Object.keys(langRef.current).filter(e => e !== curLang && langRef.current[e].available);

	const switchLangHandler = (lang) => {
		let paths = location.pathname.split('/');
		paths[1] = lang;
		history.push(paths.join('/') + window.location.search);
	}

	const facilityAndZones = facilityAndZoneRef.current.filter(e => { return e.facility_id === store.facilityId }).sort((a, b) => {
		return store.zonesRef.current[a.zone_id].sort - store.zonesRef.current[b.zone_id].sort;
	});

	if (GAParams.cm_campid != null && GAParams.cm_campid != "") {
		campaignUri += "?cm_campid=" + GAParams.cm_campid;
	} else {
		campaignUri += "?cm_campid=" + process.env.REACT_APP_CONTACT_SALE_CODE;
	}

	if (GAParams.utm_campaign != null && GAParams.utm_campaign != "") {
		campaignUri += '&utm_campaign=' + GAParams.utm_campaign;
	}

	if (GAParams.utm_content != null && GAParams.utm_content != "") {
		campaignUri += '&utm_content=' + GAParams.utm_content;
	}

	if (GAParams.utm_id != null && GAParams.utm_id != "") {
		campaignUri += '&utm_id=' + GAParams.utm_id;
	}

	if (GAParams.utm_medium != null && GAParams.utm_medium != "") {
		campaignUri += '&utm_medium=' + GAParams.utm_medium;
	}

	if (GAParams.utm_source != null && GAParams.utm_source != "") {
		campaignUri += '&utm_source=' + GAParams.utm_source;
	}

	if (GAParams.utm_term != null && GAParams.utm_term != "") {
		campaignUri += '&utm_term=' + GAParams.utm_term;
	}

	return (
		<div className={classes.main}>
			<div className={classes.logo}>
				<HeaderLogo></HeaderLogo>
			</div>

			<button className={[classes['home-btn'], 'gtm-header-home', curPageId === "home-page" ? "app-hidden" : null].join(' ')} onClick={() => { window.open(window.location.origin, "_self"); }}>{intl.formatMessage({ id: "theHeader.home" }, { br: <br /> })}</button>

			<div className={classes.middle}>
				<div className={classes['middle-left']}>
					{
						curPageId === 'facility-map' ?

						facilityAndZones.map(entry => {
							const zone = store.zonesRef.current[entry.zone_id];
							const name = intl.formatMessage({ id: entry.zone_id+".name" }, { br: <br /> });

							if (zone.avaliable === 'FALSE') {
								return null;
							}

							return (
								<p key={entry.zone_id} className={[classes['header-middle-zones'], 'gtm-header-menulinks', store.zoneId && store.zoneId === entry.zone_id ? classes['zone-active'] : ''].join(' ')} onClick={() => { store.setZoneId(entry.zone_id); store.setApplicationId(''); }}>{name}</p>
							)
						}): null
					}
				</div>
			</div>

			<a className={[classes.contact, 'gtm-header-contact'].join(' ')} href={localeInfo.contact_sale_link + campaignUri}>{intl.formatMessage({ id: "theHeader.contactSales" }, { br: <br /> })}</a>

			<div className={classes.right}>
				{
					hideLangSwitcher ? null :

					<div className={classes['lang-container']} onClick={(e) => e.stopPropagation()}>
						<div className={classes['lang-cur']}>
							<div className={classes['lang-cur-click']} onClick={() => {props.setShowLangSelector(!props.showLangSelector)}}>
								{langRef.current[curLang].name}
								<img src={arrowWhite} alt="arrow" />
							</div>
						</div>

						<div className={[classes['lang-options'], props.showLangSelector ? classes['lang-active'] : null].join(' ')}>
							{
								langOptions.map((key) => {
									return <React.Fragment key={key}><div onClick={() => { switchLangHandler(key); props.setShowLangSelector(false); props.setCurCurrency(langRef.current[key].currency); }} className={classes['lang-button']}>{langRef.current[key].name}</div></React.Fragment>
								})
							}
						</div>
					</div>
				}
			</div>
		</div>
	);
}

export default TheHeader;