
import classes from './ErrorPage.module.scss';
import { withRouter, Link } from 'react-router-dom';
import { FormattedMessage, } from 'react-intl';

const ErrorPage = (props) => {
	return (
		<div className={classes.error}>
			<div className={classes.body}>
				<h1>404</h1>
				<h2><FormattedMessage id={'error.copy'} values={{ br: <br /> }} /></h2>
				<button className={classes.btn}><Link to={'/'}><FormattedMessage id={'error.btn'} values={{ br: <br /> }} /></Link></button>
			</div>
		</div>
	);
}

export default withRouter(ErrorPage);