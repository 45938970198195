import React, { useState } from "react";
import classes from "./UnfitRemainder.module.scss";
import unfit from '../../assets/images/unfit.png'
import PopupWrapper from '../PopupWrapper/PopupWrapper'
import useWindowSize from "../../Hooks/useWindowSize";
const UnfitRemainder: React.FC = (props) => {
  const [show, setShow] = useState(true);
  useWindowSize()

  return (
    <div className={classes.main}>
      <PopupWrapper show={show} setShow={setShow}
        bodyStyle={{
          borderRadius: "1rem", width: "86vw", maxWidth: "350px",
        }}
        closeStyle={{ width: "2.5rem", height: "2.5rem" }}
        wrapperStyle={{
          zIndex: 1000,
          paddingTop:"50px",
          paddingBottom:"50px",
          overflowY: "auto",
        }}>
        <img src={unfit} alt="unfit"/>
        <h2>Sorry!</h2>
        <p>
          This app is optimized for desktop and tablet use only. If you are using a smartphone, please try on desktop or tablet.
        </p>
      </PopupWrapper>
    </div>
  );
};

export default UnfitRemainder;
