import { useLocation } from 'react-router';

interface GAParams {
	cm_campid: string | null;
	utm_campaign: string | null;
	utm_content: string | null;
	utm_id: string | null;
	utm_medium: string | null;
	utm_source: string | null;
	utm_term: string | null;
}

const useGoogleAdParams = (): GAParams => {
	const urlParams = useLocation().search;
	const URLSearchObj = new URLSearchParams(urlParams);

	return {
		"cm_campid": URLSearchObj.get("cm_campid"),
		"utm_campaign": URLSearchObj.get("utm_campaign"),
		"utm_content": URLSearchObj.get("utm_content"),
		"utm_id": URLSearchObj.get("utm_id"),
		"utm_medium": URLSearchObj.get("utm_medium"),
		"utm_source": URLSearchObj.get("utm_source"),
		"utm_term": URLSearchObj.get("utm_term")
	}
}

export default useGoogleAdParams;