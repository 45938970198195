
import classes from './HeaderLogo.module.scss';
import logo from '../../assets/logo.svg'

function HeaderLogo(props) {
  return (
    <div className={classes.container}>
      <div className={classes.logo}><a href="https://www.cognex.com/"><img src={logo} alt='logo' /></a></div>
    </div> 
    );
}

export default HeaderLogo;
