import common from "./common.json";
import applications from "./applications.json"
import resources from './resources.json'
import solutions from './solutions.json'
import zones from './zones.json'
// import all calculator json 
import calculator01 from './calculators/calculator_01.json'
import calculator02 from './calculators/calculator_02.json'
import calculator03 from './calculators/calculator_03.json'
import calculator04 from './calculators/calculator_04.json'
import calculator05 from './calculators/calculator_05.json'
import calculator06 from './calculators/calculator_06.json'
import calculator07 from './calculators/calculator_07.json'
import calculator08 from './calculators/calculator_08.json'
import calculator09 from './calculators/calculator_09.json'




const toExport = { ...common, ...applications, ...resources, ...solutions, ...zones,
    ...calculator01, ...calculator02, ...calculator03, ...calculator04, ...calculator05, 
    ...calculator06, ...calculator07, ...calculator08, ...calculator09
}
export default toExport