import React, { useContext, useState, useEffect } from "react";
import classes from "./TableGroup.module.scss";
import { getSuffix, resCurrencyFormatter } from '../../functions/index'
import GlobalStore from '../../contexts/GlobalStore';
import ResultTable from "../ResultTable/ResultTable";

enum RecommendTabLocation {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
  MIDDLE = "MIDDLE",
  SINGLE = "SINGLE"
}

const TableGroup: React.FC<{
  applicationId: string,
  baseSettings: any,
  solutionsResults: any,
  full: boolean
}> = (props) => {
  const {
    applicationId,
    baseSettings,
    solutionsResults,
    full } = props;
  const store: any = useContext(GlobalStore)

  // eslint-disable-next-line
  const [tableArrayHeights, setTableArrayHeights] = useState(new Array())
  // eslint-disable-next-line
  const [titleRowHeights, setTitleRowHeights] = useState(new Array())
  // eslint-disable-next-line
  const [recommendedSolutions, SetRecommendedSolutions] = useState<Array<any> | null>(null)

  const tableDataFormatter = (results: { any: any }, solutionId: string) => {
    const solutionName = store.solutionsRef.current[solutionId]["name"]

    // eslint-disable-next-line
    const newTables: { [key: string]: any } = new Object();
    for (let entry of Object.values(results)) {
      if (entry.display_type === 'hide') continue
      // if it's the table isn't in record, create new
      if (!newTables[entry.subtable_id]) {
        newTables[entry.subtable_id] = {
          id: entry.subtable_id,
          name: entry.subtable_name,
          rows: {},
          columns: ["", ""],
        };
      }

      // add row record if not exist
      if (!newTables[entry.subtable_id].rows[entry.variableName]) {
        newTables[entry.subtable_id].rows[entry.variableName] = {
          title:
            entry.variableName +
            getSuffix(
              entry.display_type,
              store.currenciesRef.current[store.curCurrency]["symbol"]
            ),
          id: "",
          c1: null,
          c2: null,
          cross: false,
          information: null,
          suffix: null,
        };
      }

      //skip hidden
      // if(entry.display == "hidden") continue;

      // fill row record and add column name for table
      if (entry.column_index + "" === "1") {
        // add table column name
        newTables[entry.subtable_id].columns[0] = entry.column_name.replace('{{solutionName}}', solutionName);

        // add entry row record
        newTables[entry.subtable_id].rows[entry.variableName].c1 =
          resCurrencyFormatter(
            entry.value,
            entry.display_type,
            store.currenciesRef.current[store.curCurrency]["symbol"]
          );
      } else if (entry.column_index + "" === "2") {

        // add table column name
        // newTables[entry.subtable_id].columns[0] = entry.column_name.replace('{{solutionName}}', solutionName);
        newTables[entry.subtable_id].columns[1] = entry.column_name.replace('{{solutionName}}', solutionName);

        // add entry row record
        newTables[entry.subtable_id].rows[entry.variableName].c2 =
          resCurrencyFormatter(
            entry.value,
            entry.display_type,
            store.currenciesRef.current[store.curCurrency]["symbol"]
          );
      }
      else if (
        entry.column_index + "" === "cross"
        || !entry.column_index
      ) {
        newTables[entry.subtable_id].rows[entry.variableName].cross = true;
        newTables[entry.subtable_id].columns[0] = entry.column_name.replace('{{solutionName}}', solutionName);
        newTables[entry.subtable_id].columns[1] = entry.column_name.replace('{{solutionName}}', solutionName);
        newTables[entry.subtable_id].rows[entry.variableName].c2 =
          resCurrencyFormatter(
            entry.value,
            entry.display_type,
            store.currenciesRef.current[store.curCurrency]["symbol"]
          );
      }
      else {
        console.log(entry.variableName + " ignored");
      }

      //add information
      newTables[entry.subtable_id].rows[entry.variableName].information =
        entry.information;
      newTables[entry.subtable_id].rows[entry.variableName].id =
        entry.id;
      newTables[entry.subtable_id].rows[entry.variableName].suffix =
        entry.suffix;

      // fill information
    }
    return newTables;
  };
  const getTablesContainerStyle = (solutionAmount: any) => {
    const percent = 100 / (solutionAmount + 1)
    let res = 2 * percent + "%"
    for (let i = 0; i < solutionAmount - 1; i++) {
      res += " " + percent + "%"
    }
    return res;
  }


  function getRecommendedSolutions(baseSettings: any): Array<string> | null {
    // eslint-disable-next-line
    const res = new Array();

    // For Pallet Receiving
    // J6: 0.2-1.5 m/s [40-295 ft/min]; 1.5-2.5 m/s [295-495 ft/min]
    // J7: 600-1200mm [2-4 ft]; 1200mm-2000mm [4-6.5 ft]
    // Solutions: solution_08 solution_09 solution_10
    if (applicationId === "application_05") {
      let highSpeed = false;
      let bigBox = false;
      if (baseSettings["J6"].value === "1.5-2.5 m/s [295-495 ft/min]") highSpeed = true;
      if (baseSettings["J7"].value === "1200mm-2000mm [4-6.5 ft]") bigBox = true;

      if (highSpeed === false && bigBox === false) {
        res.push("solution_08");
        res.push("solution_09");
      } else {
        res.push("solution_10");
      }
    } else if (applicationId === "application_10") {
      let highSpeed = false;
      let bigBox = false;
      if (baseSettings["J6"].value === "1.5-2.5 m/s [295-495 ft/min]") highSpeed = true;
      if (baseSettings["J7"].value === "1200mm-2000mm [4-6.5 ft]") bigBox = true;

      if (highSpeed === false && bigBox === false) {
        res.push("solution_17");
        res.push("solution_18");
      } else {
        res.push("solution_19");
      }
    } else if (applicationId === "application_11") {
      let highSpeed = false;
      let bigBox = false;
      if (baseSettings["J6"].value === "1.5-2.5 m/s [295-495 ft/min]") highSpeed = true;
      if (baseSettings["J7"].value === "1200mm-2000mm [4-6.5 ft]") bigBox = true;

      if (highSpeed === false && bigBox === false) {
        res.push("solution_20");
        res.push("solution_21");
      } else {
        res.push("solution_22");
      }
    }
    return res.length !== 0 ? res : null;
  }

  useEffect(() => {
    SetRecommendedSolutions(getRecommendedSolutions(baseSettings))
    // eslint-disable-next-line
  }, [solutionsResults])


  useEffect(() => {
    // eslint-disable-next-line
    const newTitleRowHeights = new Array()

    for (let i = 0; i < tableArrayHeights.length; i++) {
      let row = tableArrayHeights[i]
      // eslint-disable-next-line
      if (!row) row = new Array();
      for (let j = 0; j < row.length; j++) {
        newTitleRowHeights[j] = Math.max(newTitleRowHeights[j] ? newTitleRowHeights[j] : 0, row[j])
      }
    }
    setTitleRowHeights(newTitleRowHeights)
  }, [tableArrayHeights])
  return (
    <div className={[classes['table-container']].join(' ')} style={{
      paddingTop: recommendedSolutions ? "3em" : ""
    }}>
      <div className={[classes['table']].join(' ')}
        style={{ gridTemplateColumns: getTablesContainerStyle(solutionsResults.length) }}
      >
        {
          solutionsResults.map((solution: any, index: number) => {
            const tables = tableDataFormatter(solution.results, solution.solutionId)
            const tablesH = tableDataFormatter(solution.resultsH, solution.solutionId)

            let recommendTabLocation = null
            if (recommendedSolutions?.includes(solution.solutionId)) {
              recommendTabLocation = RecommendTabLocation.SINGLE
            }

            return <ResultTable
              setTitleHeightsWithRowIndex={(max: any, rowIndex: number) => {
                const newTableArrayHeights = tableArrayHeights
                // eslint-disable-next-line
                const newArray = newTableArrayHeights[index] ? newTableArrayHeights[index] : new Array()
                newArray[rowIndex] = max;
                newTableArrayHeights[index] = newArray;
                setTableArrayHeights([...newTableArrayHeights])
              }}
              recommendTabLocation={recommendTabLocation}// left, right, middle, single
              titleRowHeights={titleRowHeights}
              tables={tables}
              tablesH={tablesH}
              applicationId={applicationId}
              solutionId={solution.solutionId} index={index} full={full} key={solution.solutionId} />
          })
        }
      </div>

    </div>
  );
};

export default TableGroup;
