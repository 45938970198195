import vidyardEmbed from '@vidyard/embed-code';
import { useEffect, useRef } from 'react';
import classes from './ViyardVideo.module.scss'

const ViyardVideoModel = (props) => {
  const { viyard_video_uuid, setShowVideo } = props;

  const elRef = useRef()
  const firstTimeRender = useRef(true)

  useEffect(() => {
    firstTimeRender.current = false;
    vidyardEmbed.api.renderPlayer({
      uuid: viyard_video_uuid,
      container: elRef.current,
    });
    return () => {
      const player = vidyardEmbed.api.getPlayersByUUID(viyard_video_uuid)[0];
      vidyardEmbed.api.destroyPlayer(player);
    }
  }, [viyard_video_uuid])

  return (
    <div className={classes.main}>

      <div ref={elRef} className={classes.container}>
        <div className={["app-black-close", classes['close']].join(' ')}
          onClick={() => {
            setShowVideo(prevState => !prevState);
          }}
        />
      </div>
    </div>
  )
}

export default ViyardVideoModel