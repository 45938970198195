
import Calculator from '../../components/Calculator/Calculator';
import classes from './ApplicationView.module.scss';
import GlobalStore from '../../contexts/GlobalStore';
import { useContext, useEffect, useState } from 'react';
import ErrorPage from '../ErrorPage/ErrorPage';
// eslint-disable-next-line
import { useParams, useHistory } from 'react-router';
import InnerUserForm from './InnerUserForm/InnerUserForm';
import PopupWithFullScreenShadow from '../../components/PopupWithFullScreenShadow/PopupWithFullScreenShadow';


function ApplicationView(props) {
  const store = useContext(GlobalStore)
  const {
    thisPage,
  } = props;
  const {
    applicationId,
    applicationsRef,
    solutionsRef,
    getZoneIdByPathname,
    getApplicationIdByPathname,
    ROIAppFormSubmitted,
    setROIAppFormSubmitted
  } = store;
  const params = useParams()
  const [validUrl, setValidUrl] = useState(true)
  const [showForm, setShowForm] = useState(false)
  const application = applicationsRef.current[applicationId]
  useEffect(() => {
    document.title = thisPage.document_title;
    store.setCurPageId(thisPage.id) //set pageId
    // eslint-disable-next-line
  }, [])


  const solutions = Object.values(solutionsRef.current).filter((entry) => {
    return entry['application_id'] === applicationId
  })


  const urlNav = () => {
    if (params.zone) {
      let zoneId = getZoneIdByPathname(params.zone)
      if (zoneId)
        store.setZoneId(zoneId)
      else {
        setValidUrl(false)
      }
    }
    if (params.application) {
      let applicationId = getApplicationIdByPathname(params.application)
      if (applicationId)
        store.setApplicationId(applicationId)
      else
        setValidUrl(false)
    }
  }

  useEffect(() => {
    urlNav()
    // eslint-disable-next-line
  }, [params])

  if (!applicationId || !validUrl) {
    return <ErrorPage />
  }
  return (
    <div className={classes.main}>
      <PopupWithFullScreenShadow show={showForm && !ROIAppFormSubmitted} setShow={setShowForm} left={"50%"} top={"50%"}>
        <InnerUserForm nextFlow={() => { setShowForm(false); setROIAppFormSubmitted(true); }} />
      </PopupWithFullScreenShadow>
      <Calculator id={application['calculator_id']} solutions={solutions} openForm={() => setShowForm(true)} />
    </div>
  );
}

export default ApplicationView;
