
import classes from './MapSidebar.module.scss';
import GlobalStore from '../../../contexts/GlobalStore';
// eslint-disable-next-line
import React, { useContext, useEffect, useRef, useState } from 'react';
import arrow from '../../../assets/arrow.svg'
import whiteArrow from '../../../assets/arrow-white.svg'
import { Link, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import TheResourcesForSideBar from '../../../components/TheResourcesForSideBar/TheResourcesForSideBar';
import ViyardVideoModel from './ViyardVideo/ViyardVideo';
import NonROIForm from './NonROIForm/NonROIForm'
import PlayIcon from '../../../assets/video-play.png'
import CheckIcon from '../../../assets/images/check-icon.png'






function MapSidebar(props) {
  const store = useContext(GlobalStore)
  const intl = useIntl()
  const {
    applicationId,
  } = store

  const {
    lockMapSidebar,
    showMapSidebarBody,
    setShowMapSidebarBody,
    headerHeight,
    applications
  } = props



  return (
    <div className={[classes.main, showMapSidebarBody ? '' : classes['controll-button-hide-body']].join(' ')}
      style={{
        top: headerHeight,
        height: 'calc( 100% - ' + headerHeight + 'px )'
      }}

    >
      {
        lockMapSidebar ?
          null
          :
          <div className={classes['controll-button']} onClick={() => setShowMapSidebarBody(!showMapSidebarBody)}>
            <div className={[classes['controll-button-arrow'], showMapSidebarBody ? '' : classes['controll-button-arrow-reverse']].join(' ')}>
              <img className={classes['arrow-black']} src={arrow} alt='arrow' />
              <img className={classes['arrow-white']} src={whiteArrow} alt='arrow' />
            </div>
          </div>
      }

      <div className={classes.body}>
        {applicationId ?
          <ApplicationViewSB /> :
          <ZoneViewSB applications={applications} />}
      </div>
    </div>
  );
}

export default MapSidebar;



const ReadMore = (props) => {
  const intl = useIntl()
  const {
    text,
    children
  } = props

  const [showMore, setShowMore] = useState(false)

  let lessText = ""
  if (Array.isArray(text))
    lessText = text[0]
  else if (typeof text === 'string') {
    lessText = text.substring(0, 100);
  } else
    return text

  return (
    <div className={classes['read-more']}>
      {
        showMore ?
          (
            <div className={classes['more']}>
              <p >{text}</p>
              {children}
              <button onClick={() => setShowMore(prev => !prev)}>{intl.formatMessage({ id: "mapSideBar.readLess" }, { br: <br /> })}</button>
            </div>
          )
          :
          (
            <div className={classes['less']}>
              <p>{lessText}</p>
              <button onClick={() => setShowMore(prev => !prev)}>{intl.formatMessage({ id: "mapSideBar.readMore" }, { br: <br /> })}</button>
            </div>
          )
      }
    </div>
  )
}


const ApplicationViewSB = (props) => {
  const location = useLocation()
  const intl = useIntl()
  const store = useContext(GlobalStore)
  const {
    applicationId,
    applicationsRef,
    NonROIAppFormSubmitted,
    setNonROIAppFormSubmitted,
    ifOffline
  } = store

  const [showVideo, setShowVideo] = useState(false)
  // const [showNonROIForm, setShowNonROIForm] = useState(false)
  const sidebarBodyRef = useRef()

  useEffect(() => {
    if (applicationId) sidebarBodyRef.current.scrollTo(0, 0)
  }, [applicationId])
  const appInfo = applicationsRef.current[applicationId]
  const imgPath = "/assets/images/application_images/" + appInfo.image;
  const ifROIApp = appInfo.calculator_id && appInfo.calculator_id !== 'null';



  const applicationCopy = intl.formatMessage({ id: applicationId + ".copy" }, { br: <br /> })
  const nonROIFormTitleText = intl.formatMessage({ id: applicationId + ".app_text_facility_map_button_copy" }, { br: <br /> })
  const videoId = intl.formatMessage({ id: applicationId + '.viyard_video_uuid', defaultMessage: 'none'})
  const haveVideo = videoId!=='none' && !ifOffline;
  return (
    <div className={classes['application-version']}>
      {
        haveVideo && showVideo ?
          <ViyardVideoModel
            setShowVideo={setShowVideo}
            viyard_video_uuid={videoId}
          /> : null
      }
      <div className={classes['application-version-body']} ref={sidebarBodyRef}>
        {/* <h2 className={classes['application-name']}>{appInfo.name}</h2> */}
        <h2 className={classes['application-name']}>{intl.formatMessage({ id: applicationId + ".name" }, { br: <br /> })}</h2>
        <div className={[classes['app-img'], haveVideo ? classes.pointer : null].join(' ')}
          onClick={() => {
            if (haveVideo) setShowVideo(prev => !prev)
          }}
        >
          <img className={[haveVideo ? classes['main-img-with-video'] : null].join(' ')} src={imgPath} alt={appInfo.image_alt ? intl.formatMessage({ id: applicationId + ".image_alt" }, { br: <br /> }) : intl.formatMessage({ id: applicationId + ".name" }, { br: <br /> })} />
          {
            haveVideo ?
              <img src={PlayIcon} className={classes['play-icon']} alt="play" />
              : null
          }
        </div>
        <ReadMore text={applicationCopy} >
          <ul className={classes['application-list']}>
            {
              intl.formatMessage({ id: applicationId + ".copy_list" }).split(';').map((info, index) => {
                return info ? <li className={'app-list-item'} key={info + index}>{info}</li> : null
              })
            }
          </ul>
        </ReadMore>

        <TheResourcesForSideBar applicationId={appInfo.id} amountPerPage={undefined} />
      </div>
      {
        ifROIApp ?
          <div className={[classes['btn-view-solutions-container']].join(' ')}>
            <p><FormattedMessage id={applicationId + ".app_text_facility_map_button_copy"} values={{ br: <br /> }} /></p>
            <Link to={location.pathname + '/roi' + window.location.search} className={['app-button-style-blue-fill', 'gtm-sidebar-app-optimize', classes['btn-view-solutions']].join(' ')}>
              {intl.formatMessage({ id: "app.calculateROI" }, { br: <br /> })}
            </Link>
          </div>
          :
          <div className={[classes['btn-view-solutions-container'], classes['btn-view-solutions-container-non-roi']].join(' ')}>
            {
              NonROIAppFormSubmitted &&
              <div className={classes['non-roi-form-ty-container']}>
                <div className={classes['non-roi-form-ty-pop-up']}>
                  <img src={CheckIcon} alt='check-icon' />
                  <p>
                    {intl.formatMessage({ id: "mapSideBar.submitted" }, { br: <br /> })}
                  </p>
                  <div className={["app-black-close", classes['close']].join(' ')}
                    onClick={() => {
                      setNonROIAppFormSubmitted(prevState => !prevState);
                    }}
                  />
                </div>
              </div>
            }
            <React.Fragment>
              {
                nonROIFormTitleText && <h3>{nonROIFormTitleText}</h3>
              }
              < NonROIForm nextFlow={() => setNonROIAppFormSubmitted(true)} />
            </React.Fragment>
          </div>
      }
    </div>
  )
}



const ZoneViewSB = (props) => {
  const store = useContext(GlobalStore)
  const {
    applicationsRef,
    setApplicationId,
    zoneId,
  } = store
  const {
    applications
  } = props
  const intl = useIntl()

  const zoneInfo = store.zonesRef.current[zoneId]

  return (
    <div className={classes['zone-version']}>
      <h2 className={classes['zone-name']}>{intl.formatMessage({ id: zoneId + ".name" }, { br: <br /> })}</h2>
      <p className={classes['zone-copy']}>{intl.formatMessage({ id: zoneId + ".sidebar_copy" }, { br: <br /> })}</p>
      <div className={classes['zone-applications']}>
        {
          applications.map((applicationId) => {
            return (
              <div className={[classes['zone-applications-main'], applicationsRef.current[applicationId].avaliable === "TRUE" ? "" : "app-display-none"].join(" ")} key={applicationId}
                onClick={() => {
                  setApplicationId(applicationId)
                }}>
                <div className={[classes['zone-view-application-name-container'], 'gtm-sidebar-application'].join(" ")}>
                  <h3
                    className={[classes['zone-view-application-name'], 'app-title-arrow'].join(" ")}
                  >{intl.formatMessage({ id: applicationId + ".name" }, { br: <br /> })}</h3>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
