
import classes from './ROIGraph.module.scss';
import { AreaChart, Area, XAxis, YAxis, ReferenceLine, CartesianGrid, Tooltip, ResponsiveContainer, ReferenceArea } from 'recharts';
import React from 'react';
import GlobalStore from '../../../contexts/GlobalStore';
import { useContext } from 'react';
import { useIntl } from 'react-intl';
import { resCurrencyFormatter } from '../../../functions/index'


function ROIGraph(props) {
  const store = useContext(GlobalStore);
  const { solutionsResults } = props;
  const solutionsRef = store.solutionsRef
  const intl = useIntl();
  const config = props.config ? props.config : {
    "Days for Return on Investment": "J9",
    "Cost of Solution": "J23"
  }

  const unit = props.unit ? props.unit : "day"

  const cssParamGridColumns = solutionsResults.length % 2 === 0 ? 2 : 3;



  const fillMorePoints = (p1, p2, orgXMax, interval) => {
    const res = []
    const k = (p2[1] - p1[1]) / (p2[0] - p2[1]);
    const b = (p1[0] * p2[1] - p2[0] * p1[1]) / (p1[0] - p2[0]);
    const end = Math.ceil((orgXMax / interval) * 1.5)
    const xStart = Math.min(p1[0], p2[0])

    for (let i = 0; i < end; i++) {
      const currX = xStart + i * interval
      res.push([currX, k * currX + b])
    }
    return res
  }
  const getOrgXMax = (solutionsResults) => {
    let xMax = Number.MIN_SAFE_INTEGER;
    for (let solutionsResult of solutionsResults) {
      let value = solutionsResult.resultsH[config["Days for Return on Investment"]].value
      if (unit === 'month') value /= 30;
      xMax = Math.max(xMax, value)
    }
    return xMax;
  }

  /**
   *
   * @param {*} solutionsResults
   * @param {*} orgXMax  used to produce more points in range [0, orgXMax]
   * @returns
   */
  const getLineList = (solutionsResults) => {
    const orgXMax = getOrgXMax(solutionsResults)
    const res = [];
    // line is an object
    // point is an array of length 2, point[0] = x, point[1] = y
    for (let solutionsResult of solutionsResults) {
      let value = solutionsResult.resultsH[config["Days for Return on Investment"]].value
      if (unit === 'month') value /= 30;
      let interval = 10
      if (unit === 'month') interval = 1


      const line = {};
      line['solutionId'] = solutionsResult.solutionId
      line['points'] = []
      line['gap'] = Math.abs(solutionsResult.resultsH[config["Cost of Solution"]].value - solutionsResult.results[config["Cost of Solution"]].value);
      line['points'] = fillMorePoints([0, -solutionsResult.resultsH[config["Cost of Solution"]].value], [value, 0], orgXMax, interval)
      res.push(line);
    }
    return res;
  }
  const getAreaGraphDataFromLineList = (lineList) => {
    const res = {}
    for (let i = 0; i < lineList.length; i++) {
      for (let index = 0; index < lineList[i].points.length; index++) {
        const x = lineList[i].points[index][0]
        if (!res[x]) res[x] = {}
        res[x].x = lineList[i].points[index][0]
        res[x][`line${i}_lower`] = lineList[i].points[index][1]
        res[x][`line${i}_upper`] = lineList[i].gap
      }
    }
    return res
  }

  const getDataMinMax = (lineList) => {
    let [xMin, xMax, yMin, yMax] = [Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER, Number.MAX_SAFE_INTEGER, Number.MIN_SAFE_INTEGER];
    for (let i = 0; i < lineList.length; i++) {
      for (let index = 0; index < lineList[i].points.length; index++) {
        const x = lineList[i].points[index][0];
        const y = lineList[i].points[index][1];
        const yH = lineList[i].points[index][1] + lineList[i].gap;
        xMin = Math.min(xMin, x);
        xMax = Math.max(xMax, x);
        yMin = Math.min(yMin, y, yH);
        yMax = Math.max(yMax, y, yH);
      }
    }
    return [xMin, xMax, yMin, yMax]
  }

  const getMinsYPerLine = (lineList) => {
    const res = {};
    for (let i = 0; i < lineList.length; i++) {
      let min = Number.MAX_SAFE_INTEGER;
      for (let index = 0; index < lineList[i].points.length; index++) {
        const y = lineList[i].points[index][1];
        const yH = lineList[i].points[index][1] + lineList[i].gap;
        min = Math.min(min, y, yH);
      }
      res[lineList[i].solutionId] = Math.round(min);
    }
    return res;
  }

  const getBigRound = (number) => {
    let value = parseInt(number)
    const sign = value <= 0 ? -1 : 1;
    value = Math.abs(value);
    const length = (value + "").length
    let scale = '1'
    for (let i = 0; i < length - 1; i++) {
      scale += '0'
    }
    scale = parseInt(scale)
    const res = Math.floor(value / scale) * scale + scale
    return sign * res
  }



  const colors = ["#FFF200", "#FCB040", "#016AC0", "#003B6C"]
  const lineList = getLineList(solutionsResults)
  const [xMin, xMax, yMin, yMax] = getDataMinMax(lineList)
  const [graphXMin, graphXMax, graphYMin, graphYMax] = [
    xMin,
    xMax,
    getBigRound(yMin),
    getBigRound(yMax)
  ]
  const yMinMap = getMinsYPerLine(lineList)

  const areaGraphData = getAreaGraphDataFromLineList(lineList)
  let XLabel = "Days"
  if (unit === 'month') {
    XLabel = "Months"
  }

  return (
    <div className={classes["graph-box"]}>
      <h3>Time of Return on Investment • <span>Savings ({store.currenciesRef.current[store.curCurrency]['symbol']})</span></h3>
      <div className={classes['main']}>
        <div style={{ "--columns": cssParamGridColumns }} className={classes['label-box']}>
          {
            solutionsResults.map((solution, index) => {
              return (
                <div key={solution.solutionId} className={classes.label}>
                  <div style={{ backgroundColor: colors[index] }}></div>
                  <p >{
                    intl.formatMessage({ id: solution.solutionId + ".abbr_name" }, { br: <br /> }) ?
                      intl.formatMessage({ id: solution.solutionId + ".abbr_name" }, { br: <br /> }) :
                      intl.formatMessage({ id: solution.solutionId + ".name" }, { br: <br /> })
                  }</p>
                </div>
              )
            })
          }
        </div>
        <div className={classes['graph-container']}>
          <div className={classes['graph-content-wrapper']}>
            <ResponsiveContainer width="100%" height="100%">
              <AreaChart
                // width={500}
                // height={300}
                data={Object.values(areaGraphData)}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <ReferenceLine y={0} label={{ position: 'right', value: '0', fill: 'blue', fontSize: 14 }} stroke="blue" strokeDasharray="3 3" />
                <ReferenceArea x1={graphXMin} x2={graphXMax} y1={0} y2={graphYMin} fill="red" fillOpacity={0.1} />
                <ReferenceArea x1={graphXMin} x2={graphXMax} y1={0} y2={graphYMax} fill="green" fillOpacity={0.1} />
                <XAxis dataKey="x"
                  type="number"
                  domain={[graphXMin, graphXMax]}
                >
                  {/* <Label value="Days" position="insideBottom" offset={-1} /> */}
                </XAxis>

                <YAxis
                  tickFormatter={(number) => {
                    return resCurrencyFormatter(number, "currency", store.currenciesRef.current[store.curCurrency]['symbol'])
                    // return store.currenciesRef.current[store.curCurrency]['symbol'] + numberFormater(number)
                  }}
                  domain={[graphYMin, graphYMax]}
                >
                  {/* <Label value="Days" position="insideLeft" offset={-10} angle={-90}/> */}
                </YAxis>
                <Tooltip
                  content={<CustomTooltip yMinMap={yMinMap} unit={unit} />}
                />
                {
                  lineList.map((line, index) => {
                    return (
                      <React.Fragment key={line.solutionId}>
                        <Area type="monotone" stackId={index} name={line.solutionId} dataKey={`line${index}_lower`} stroke={colors[index % colors.length]} fill={colors[index % colors.length]} fillOpacity="0" />
                        <Area type="monotone" stackId={index} name="skip" dataKey={`line${index}_upper`} stroke={colors[index % colors.length]} fill={colors[index % colors.length]} fillOpacity="0.5" />
                      </React.Fragment>
                    )
                  })
                }

              </AreaChart>
            </ResponsiveContainer>
          </div>
          <h5>{XLabel}</h5>

        </div>

      </div>
    </div>
  );
}

export default ROIGraph;



const CustomTooltip = (props) => {
  const { active, payload, label, yMinMap } = props;
  const store = useContext(GlobalStore);
  if (!(active && payload && payload.length)) return null
  let fullLabel = label + " Days"
  if (props.unit === 'month') {
    fullLabel = label + " Months"
  }
  return (
    <div className={[classes["custom-tooltip"], "app-box-shadow"].join(" ")}>
      <p className={classes["label"]}>{fullLabel}</p>
      {
        payload.map(y => {
          if (y.name === 'skip') return null
          const value = parseInt(y.value) - yMinMap[y.name]
          const formatted = resCurrencyFormatter(value, 'currency', store.currenciesRef.current[store.curCurrency]['symbol'])
          return <p key={y.name} className={classes["intro"]}><span style={{ color: y.color, marginRight: "1rem" }}>{String.fromCharCode(0x2587)}</span>{formatted + " Saved"}</p>
        })
      }
    </div>
  );
};