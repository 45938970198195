
import classes from './UnitTable.module.scss';
import React, { useEffect, useRef } from 'react'
import useWindowSize from '../../../Hooks/useWindowSize';

function UnitTable(props) {
  const setMax = props.setMax
  const { table, tableH, hideLeft } = props;
  const showTitle = table.name !== '' ||
    table.columns[0] !== '' ||
    table.columns[1] !== ''

  const rowsArray = Object.values(table.rows).sort((a, b) => {
    if (a.order && b.order) {
      return parseInt(a.order) >= parseInt(b.order) ? 1 : -1
    } else {
      return parseInt((a.id + "").replace(/\D/g, '')) >= parseInt((b.id + "").replace(/\D/g, '')) ? 1 : -1
    }
  })
  const rowsArrayH = Object.values(tableH.rows).sort((a, b) => {
    if (a.order && b.order) {
      return parseInt(a.order) >= parseInt(b.order) ? 1 : -1
    } else {
      return parseInt((a.id + "").replace(/\D/g, '')) >= parseInt((b.id + "").replace(/\D/g, '')) ? 1 : -1
    }
  })
  const titleRowHeight = props.titleRowHeight

  const windowSize = useWindowSize()

  const refs = useRef((() => {
    const tem = []
    for (let i = 0; i < 3; i++) {
      tem.push(React.createRef());
    }
    return tem;
  })())

  const getMax = () => {
    let max = 0;
    for (let ref of refs.current) {
      max = Math.max(max, ref.current.clientHeight);
    }
    return max;
  }

  useEffect(() => {
    setMax(getMax());
    // eslint-disable-next-line
  }, [windowSize])
  const getCellStyle = (number) => {
    if (!number) return null;
    return {
      position: "relative",
      width: 100 * number + "%",
      left: -100 * (number - 1) + "%"
    }
  }

  const columnCellStyle = getCellStyle(table['columns-style-merge'])
  return (
    <div className={[classes.main, hideLeft ? classes.single : ''].join(' ')}>
      <div className={[classes['title-row'], classes['row'], showTitle ? null : 'app-display-none'].join(' ')} style={{ height: titleRowHeight * 1.8 + "px" }}>
        <h4 ref={refs.current[0]} className={[classes['row-title'], hideLeft ? 'app-display-none' : ''].join(' ')}>{table.name}</h4>
        {
          table.columns[0] === table.columns[1] ?
            <div className={[classes['row-value']].join(' ')} style={{ ...columnCellStyle, gridTemplateColumns: "1fr", background: "inherit" }}>
              <h4 ref={refs.current[1]} >{table.columns[0]}</h4>
              <h4 ref={refs.current[2]} style={{ display: "none" }} >{table.columns[1]}</h4>
            </div>
            :
            <div className={[classes['row-value']].join(' ')}>
              <h4 ref={refs.current[1]} >{table.columns[0]}</h4>
              <h4 ref={refs.current[2]} >{table.columns[1]}</h4>
            </div>
        }

      </div>
      {
        rowsArray.map(
          (row, index) => {
            const rowH = rowsArrayH[index]

            const cellStyle = getCellStyle(row['style-merge'])
            let column1Text = ''
            let column2Text = ''
            if (row.c1 === rowH.c1) {
              column1Text = typeof row.c1 === 'number' ? Number.parseFloat(row.c1.toFixed(3)) : row.c1
            } else {
              column1Text = (typeof row.c1 === 'number' ? Number.parseFloat(row.c1.toFixed(3)) : row.c1) + "-" + (typeof rowH.c1 === 'number' ? Number.parseFloat(rowH.c1.toFixed(3)) : rowH.c1)
            }

            if (row.c2 === rowH.c2) {
              column2Text = (typeof row.c2 === 'number' ? Number.parseFloat(row.c2.toFixed(4)) : row.c2) + " " + row.suffix
            } else {
              column2Text = (typeof row.c2 === 'number' ? Number.parseFloat(row.c2.toFixed(4)) : row.c2) + "-" + (typeof rowH.c2 === 'number' ? Number.parseFloat(rowH.c2.toFixed(4)) : rowH.c2) + " " + row.suffix
            }


            if (typeof column2Text === 'string' && column2Text) {
              const values = column2Text.split('-')
              if (values.length === 2) {
                const v1 = values[0]
                const v2 = values[1]
                if (parseInt(v1.replace(/\$/, '')) > parseInt(v2.replace(/\$/, ''))) {
                  column2Text = v2 + "-" + v1
                }
              }
            }

            return (
              <div className={[classes['row']].join(' ')} key={row.title}
                style={cellStyle}
              >
                <p className={[classes['row-title'], hideLeft ? 'app-display-none' : '',].join(' ')}>{row.title}</p>
                <div className={[classes['row-value'], row.cross ? classes['row-single-value'] : ''].join(' ')}>
                  {
                    row['url'] ?
                      <>
                        <a href={row['url']} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: column1Text }}></a>
                        <a href={row['url']} target="_blank" rel="noreferrer" dangerouslySetInnerHTML={{ __html: column2Text }}></a>
                      </>
                      :
                      <>
                        <p dangerouslySetInnerHTML={{ __html: column1Text }}></p>
                        <p dangerouslySetInnerHTML={{ __html: column2Text }}></p>
                      </>
                  }
                </div>
              </div>
            )
          }
        )
      }
    </div>
  );
}

export default UnitTable;
