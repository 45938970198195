import classes from './TheResources.module.scss';
import { useState, useRef } from 'react';
import useWindowSize from '../../Hooks/useWindowSize';
import { useIntl } from 'react-intl';

function TheResources(props) {
	const [curPage, setCurPage] = useState(0);
	const applicationAndResourcesRef = useRef(require('../../configs/applicationAndResource.json'));
	const applicationId = props.applicationId;
	const intl = useIntl();
	const pages = [];
	const resourcesRef = useRef(require('../../configs/resources.json'));
	const size = useWindowSize();

	const resourceIdArray = applicationAndResourcesRef.current.filter(entry => applicationId === entry["application_id"]).map(entry => entry["resource_id"]);
	const resources = resourceIdArray.map(key => { return resourcesRef.current[key] })

	let amountPerPage = props.amountPerPage ? props.amountPerPage : 6;
	let amountPerPageRow = props.amountPerPageRow ? props.amountPerPageRow : 3;
	let p = 0;

	if (size.width > 690 && size.width <= 1090) {
		amountPerPage = 3;
		amountPerPageRow = 3;
	} else if (size.width > 490 && size.width <= 690) {
		amountPerPage = 2;
		amountPerPageRow = 2;
	} else if (size.width <= 490) {
		amountPerPage = 1;
		amountPerPageRow = 1;
	}

	resources.sort((a, b) => a.sort - b.sort);

	while (p < resources.length) {
		const onePage = [];

		for (let i = 0; i < amountPerPage; i++) {
			if (p === resources.length) {
				break;
			}

			onePage.push(resources[p++]);
		}

		pages.push(onePage);
	}

	if (resources.length === 0) {
		return null;
	}

	return (
		<div className={[classes['resource-box'], "no-print"].join(" ")}>
			<div className={[classes['resource-box-title']].join(" ")}>
				<h3>{intl.formatMessage({ id: "theResources.title" }, { br: <br /> })}</h3>
				<p>{intl.formatMessage({ id: "theResources.copy" }, { br: <br /> })}</p>
			</div>

			<div className={classes.resources}>
				{
					pages.map((page, index) => {
						return (
							<div className={[classes.page, curPage === index ? '' : "app-none"].join(' ')} key={page + index} style={{gridTemplateColumns: `repeat(${amountPerPageRow},${(95 / amountPerPage)}%)`,}}>
								{page.map(resource => {
									return (
										<div key={resource.id}>
											<a href={intl.formatMessage({ id: resource.id + ".link" })} target="_blank" rel="noreferrer">
												<img src={'/assets/images/resource_images/' + resource.image} alt={resource.image_alt ? intl.formatMessage({ id: resource.id+".image_alt" }, { br: <br /> }) : intl.formatMessage({ id: resource.id+".name" }, { br: <br /> })} />

												<p>
													{intl.formatMessage({ id: resource.id+".name" }, { br: <br /> })}
												</p>
											</a>
										</div>
									)
								})}
							</div>
						)
					})
				}

				<div className={classes['page-buttons']}>
					{
						pages.map((page, index) => {
							return (
								<button key={index + "test"} className={[index === curPage ? classes.active : null, pages.length === 1 ? "app-none" : null].join(" ")} onClick={() => { if (curPage !== index) { setCurPage(index); } }}>{index + 1}</button>
							)
						})
					}
				</div>
			</div>
		</div>
	);
}

export default TheResources;