
import classes from './HoverBubble.module.scss';


function HoverBubble(props) {
  const hoverDirection = props.hoverDirection // facilityAndZone['hover']
  const hoverX = props.hoverX // facilityAndZone['hover_x']
  const hoverY = props.hoverY //facilityAndZone['hover_y']
  const backgroundColor = props.backgroundColor ? props.backgroundColor : "white";
  const showBorder = props.showBorder


  // set the size default to 22px
  const size = props.size ? props.size : 22;
  // the gap between hover div and main div
  let hoverGap = size < 22 ? '0rem' : '1rem';


  let hoverMainStyles = null
  let hoverBodyStyles = null
  let hoverTriangleStyles = null
  if (hoverDirection === 'top') {

    hoverMainStyles = {
      left: hoverX,
      top: hoverY, 
      transform: `translateY( calc(-100% - ${size / 2}px )) translateX( calc(-20% - ${size}px) )`
    }
    hoverBodyStyles = {
      bottom: hoverGap,
      borderLeft: showBorder ? 'var(--color-school-bus-yellow) 5px solid ' : null
    }
    hoverTriangleStyles = {
      position: 'absolute', width: '0', height: '0', zIndex: '10',
      bottom: '-10px',
      left: "20%",
      borderTop: `${size}px solid ${backgroundColor}`,
      borderRight: `${size}px solid transparent`,
      borderLeft: `${size}px solid transparent`,
    }
  } else if (hoverDirection === 'bottom') {
    hoverMainStyles = {
      left: hoverX,
      top: hoverY, 
      transform: `translateY(${size}px) translateX( calc(-20% - ${size}px) )`
    }
    hoverBodyStyles = { top: hoverGap, borderLeft: showBorder ? 'var(--color-school-bus-yellow) 5px solid ' : null }
    hoverTriangleStyles = {
      position: 'absolute', width: '0', height: '0', zIndex: '10',
      top: '-10px',
      left: "20%",
      borderBottom: `${size}px solid ${backgroundColor}`,
      borderRight: `${size}px solid transparent`,
      borderLeft: `${size}px solid transparent`,
    }
  } else if (hoverDirection === 'right') {
    hoverMainStyles = {
      left: hoverX,
      top: hoverY, 
      transform: `translateX(0%) translateY(calc(-20% - ${size}px))`
    }
    hoverBodyStyles = { left: hoverGap, borderRight: showBorder ? 'var(--color-school-bus-yellow) 5px solid ' : null }
    hoverTriangleStyles = {
      position: 'absolute', width: '0', height: '0', zIndex: '10',
      right: '-10px',
      bottom: "20%",
      borderRight: `${size}px solid ${backgroundColor}`,
      borderTop: `${size}px solid transparent`,
      borderBottom: `${size}px solid transparent`,
    }
  } else if (hoverDirection === 'left') {
    hoverMainStyles = {
      left: hoverX,
      top: hoverY, 
      transform: `translateX(-100%) translateY(calc(-20% - ${size}px))`
    }
    hoverBodyStyles = { right: hoverGap, borderLeft: showBorder ? 'var(--color-school-bus-yellow) 5px solid ' : null }
    hoverTriangleStyles = {
      position: 'absolute', width: '0', height: '0', zIndex: '10',
      right: '-10px',
      top: "20%",
      borderLeft: `${size}px solid ${backgroundColor}`,
      borderTop: `${size}px solid transparent`,
      borderBottom: `${size}px solid transparent`,
    }
  } else if (hoverDirection === 'calculator-popup-right') {
    hoverMainStyles = {
      left: hoverX,
      top: hoverY, 
      transform: `translateY(calc(-20% - ${size}px))`
    }
    hoverBodyStyles = { left: hoverGap }
    hoverTriangleStyles = {
      position: 'absolute', width: '0', height: '0', zIndex: '10',
      left: '-10px',
      top: "50%",
      borderRight: `${size}px solid ${backgroundColor}`,
      borderTop: `${size}px solid transparent`,
      borderBottom: `${size}px solid transparent`,
    }
  }
  return (
    <div className={[classes['zone-hover-main']].join(' ')} style={hoverMainStyles}>
      <div style={hoverTriangleStyles}></div>
      <div className={[classes['zone-hover-body'], 'app-box-shadow'].join(' ')} style={hoverBodyStyles}>
        {props.children}
        {/* <button className={['app-button-style-1'].join(' ')} >Next view</button> */}
      </div>
    </div>
  );
}

export default HoverBubble;
