
import classes from './TheResourcesForSideBar.module.scss';
import { useState, useRef, useEffect } from 'react';
// import useWindowSize from '../../Hooks/useWindowSize'
import { useIntl } from 'react-intl'

function TheResourcesForSideBar(props) {
  // Per client request, adding config to limit show 2 resources
  const showTwo = true
  const intl = useIntl()
  let amountPerPage = props.amountPerPage ? props.amountPerPage : 4;
  const applicationId = props.applicationId
  const resourcesRef = useRef(require('../../configs/resources.json'))
  const applicationAndResourcesRef = useRef(require('../../configs/applicationAndResource.json'))
  const resourceIdArray = applicationAndResourcesRef.current.filter(entry => applicationId === entry["application_id"]).map(entry => entry["resource_id"])
  const resources = resourceIdArray.map(key => { return resourcesRef.current[key] })
  const [curPage, setCurPage] = useState(0);

  resources.sort((a, b) => a.sort - b.sort)
  let pages = []
  let p = 0;
  while (p < resources.length) {
    const onePage = []
    for (let i = 0; i < amountPerPage; i++) {
      if (p === resources.length) break;
      onePage.push(resources[p++]);
    }
    pages.push(onePage);
  }

  useEffect(() => {
    setCurPage(0)
  }, [applicationId])

  if (showTwo && pages[0]) {
    amountPerPage = 4;
    const newPage = [pages[0][0], pages[0][1]]
    pages = [newPage]
  }

  if (resources.length === 0) return null;

  return (
    <div className={[classes['resource-box'], "no-print"].join(" ")}>
      <h2>{intl.formatMessage({ id: "theResources.resources" }, { br: <br /> })}</h2>
      <div className={classes.resources}>
        {
          pages.map((page, index) => {
            return (
              <div className={[classes.page, curPage === index ? '' : "app-none"].join(' ')} key={page + index}
                style={{
                  gridTemplateColumns: `repeat(${amountPerPage},${(90 / amountPerPage)}%)`,
                }}>
                {page.map(resource => {
                  if (!resource) return null
                  return (
                    <div key={resource.id}>
                      <a href={intl.formatMessage({ id: resource.id + ".link" })} target="_blank" rel="noreferrer">
                      <img src={'/assets/images/resource_images/' + resource.image} alt={resource.image_alt ? intl.formatMessage({ id: resource.id+".image_alt" }, { br: <br /> }) : intl.formatMessage({ id: resource.id+".name" }, { br: <br /> })} />
                        <p>
                          {intl.formatMessage({ id: resource.id+".name" }, { br: <br /> })}
                        </p>
                      </a>
                    </div>
                  )
                })}


              </div>
            )
          })
        }
        <div className={classes['page-buttons']}>
          {
            pages.map((page, index) => {
              return (
                <button
                  key={index + "test"}
                  className={[index === curPage ? classes.active : null, pages.length === 1 ? "app-none" : null].join(" ")}
                  onClick={() => {
                    if (curPage !== index) {
                      setCurPage(index);
                    }
                  }}
                >{index + 1}</button>
              )
            })
          }
        </div>
      </div>


    </div>
  );
}

export default TheResourcesForSideBar;
