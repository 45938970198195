import React, { useEffect, useState } from "react";
import classes from "./UpdateRemainder.module.scss";
import update from '../../assets/images/update.png'
import PopupWrapper from '../PopupWrapper/PopupWrapper'
import useWindowSize from "../../Hooks/useWindowSize";
import { useIntl } from 'react-intl';




const UpdateRemainder: React.FC = (props: any) => {
  const intl = useIntl()
  const [show, setShow] = useState(false)
  useWindowSize()
  async function watchUpdate() {
    const registration = await navigator.serviceWorker.getRegistration();
    const handler = () => {
      setShow(true)
    }
    if (registration) { // if there is a SW active
      registration.addEventListener('updatefound', handler);
    }
  }
  useEffect(() => {
    watchUpdate()
  })
  return (
    <div className={classes.main}>
      <PopupWrapper show={show} setShow={setShow}
        bodyStyle={{
          borderRadius: "1rem", width: "86vw", maxWidth: "350px",
        }}
        closeStyle={{ width: "2.5rem", height: "2.5rem" }}
        wrapperStyle={{
          zIndex: 1000,
          paddingTop: "50px",
          paddingBottom: "50px",
          overflowY: "auto",
        }}>
        <img src={update} alt="update" />
        <h2>{intl.formatMessage({ id: "updateRemainder.pleaseRefresh" }, { br: <br /> })}</h2>
        <p>{intl.formatMessage({ id: "updateRemainder.pleaseRefreshCopy" }, { br: <br /> })}</p>
        <button className="app-button-style-1" onClick={() => {
          window.location.reload()
        }}>{intl.formatMessage({ id: "updateRemainder.refreshNow" }, { br: <br /> })}</button>
      </PopupWrapper>
    </div>
  );
};

export default UpdateRemainder;
