
const getItem = (key: string) => {
  let res;
  if (typeof document !== 'undefined') {
    document.cookie.split('; ').forEach((currentCookie) => {
      const item = currentCookie.split('=');
      const storedKey = item[0];
      const storedValue = item[1];
      if(key === storedKey) res = decodeURIComponent(storedValue);
    }, '');
  }
  return res;
};

const setItem = (key: string, value: string | boolean, numberOfDays: number) => {
  const now = new Date();

  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000);

  if (typeof document !== 'undefined') {
    document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`;
  }
};

export const useCookie = () => {
  return [getItem, setItem];
};