import classes from './TheHome.module.scss';
// eslint-disable-next-line
import { FormattedMessage } from 'react-intl';
import GlobalStore from '../../contexts/GlobalStore';
import { useContext, useEffect } from 'react';
import { levelTwoPageSwitcher } from '../../functions/routerFunctions';
import { useHistory } from 'react-router';

function TheHome(props) {
  const store = useContext(GlobalStore)
  const thisPage = props.thisPage
  const history = useHistory()
  useEffect(() => {
    document.title = thisPage.document_title;
    store.setCurPageId(thisPage.id)
    props.resetStates()
    // eslint-disable-next-line
  }, [])

  return (
    <div className={classes.main}>
      <div className={classes.left}>
        <video autoPlay loop muted>
          <source src={'/assets/video/home-video.mp4'} type='video/mp4' />
        </video>

      </div>
      <div className={classes.right}>
        <div className={classes['right-texts']}>
          <h2><FormattedMessage id={'thehome.right.title'} values={{ br: <br /> }} /></h2>
          <p><FormattedMessage id={'thehome.right.copy'} values={{ br: <br /> }} /></p>
          <p className="app-list-item"><FormattedMessage id={'thehome.right.p1'} /></p>
          <p className="app-list-item"><FormattedMessage id={'thehome.right.p2'} /></p>
          <p className="app-list-item"><FormattedMessage id={'thehome.right.p3'} /></p>
          <button
            onClick={() =>
              levelTwoPageSwitcher(history.push, props.nextPage.pathname)
            }
            className={['app-button-style-1', 'gtm-home-start'].join(' ')}
          ><FormattedMessage id={'app.getstarted'} /></button>
        </div>
      </div>
    </div>
  );
}

export default TheHome;
