import React from 'react';


const GlobalStore = React.createContext({
    sidebarSupport: null,
    sidebarResetNotifier: null,
    setSidebarResetNotifier: null,
    facilityId: null,
    zoneId: null,
    setZoneId: null,
    applicationId: null,
    setApplicationId: null,
    solutionId: null,
    setSolutionId: null,
    applicationsRef: null,
    zonesRef: null,
    solutionsRef: null,
    curCurrency: null,
    currenciesRef: null,
});


export default GlobalStore;