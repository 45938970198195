import { useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'

import German from '../configs/languages/de/index'
import English from '../configs/languages/en/index'
import French from '../configs/languages/fr/index'
import Italian from '../configs/languages/it/index'
import Spanish from '../configs/languages/es/index'
import Portuguese from '../configs/languages/pt/index'
import ChineseSimplified from '../configs/languages/zh-cn/index'
import ChineseTraditional from '../configs/languages/zh-tw/index'
import Japanese from '../configs/languages/ja/index'
import Korean from '../configs/languages/ko/index'

import { useLocation } from 'react-router-dom'


const MultiLangWrapper = (props) => {
    const location = useLocation()
    const [lang, setLang] = useState(English)

    const idToMessage = {
        "de": German,
        "en": English,
        "fr": French,
        "it": Italian,
        "es": Spanish,
        "pt": Portuguese,
        "zh-cn": ChineseSimplified,
        "zh-tw": ChineseTraditional,
        "ja": Japanese,
        "ko": Korean
    };

    useEffect(() => {
        const langId = parseLang(location.pathname)
        const newLang = parseLangId(langId, idToMessage)
        setLang(newLang)
        // eslint-disable-next-line
    }, [location])


    const parseLang = (pathname) => {
        const pathes = pathname.split('/')
        return pathes[1]
    }

    const parseLangId = (langId, idToMessage) => {
        langId = langId + ''
        let newLang = idToMessage[langId]

        // by default go to English
        return newLang ? newLang : English;
    }


    return (
        <IntlProvider locale={navigator.language} messages={lang}
            onError={() => { }}
        >
            {/*
                messages: the texts you wanna use
                locale: for date format
            */}
            {props.children}
        </IntlProvider>
    )
}

export default MultiLangWrapper;