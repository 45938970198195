import classes from "./Calculator.module.scss";
import { useState, useEffect, useRef } from "react";
import { useParams, useHistory, useLocation } from 'react-router-dom';
import {
    calculateMathFormula,
    formulaConvertor,
    getSuffix,
} from "../../functions/index";
import { v4 as uuidv4 } from 'uuid';
import GlobalStore from "../../contexts/GlobalStore";
import { useContext } from "react";
import ErrorPage from "../../pages/ErrorPage/ErrorPage";
import AdvSettingInputEl from "../AdvSettingInputEl/AdvSettingInputEl";
import HoverBubble from "../HoverBubble/HoverBubble";
import { useCookie } from "../../Hooks/useCookie";
// import iImg from '../../assets/images/i.png'
import infoImg from '../../assets/info.png'
import TheResults from "../TheResults/TheResults";
import axios from 'axios';
import mockImg1 from '../../assets/images/mock1.png'
import mockImg2 from '../../assets/images/mock2.png'
import mockImg3 from '../../assets/images/mock3.png'
import { useIntl } from 'react-intl';


function Calculator(props) {
    const intl = useIntl()
    const store = useContext(GlobalStore);
    const {
        postData,
        formData
    } = store
    const formSubmitted = useRef(false)


    // eslint-disable-next-line
    const [getItem, setItem] = useCookie();
    const location = useLocation()
    const params = useParams();
    const history = useHistory();
    const applicationsRef = useRef(require("../../configs/applications.json"))


    const setFormData = store.setFormData;
    const setConfigData = store.setConfigData;
    const userId = store.userId;
    const solutions = props.solutions;
    const openForm = props.openForm;

    const firstRendering = useRef(true)
    const ifHasIdRef = useRef((() => {
        let idToFetch = null;
        const query = new URLSearchParams(location.search);
        for (let param of query.entries()) {
            if (param[0] === 'id') idToFetch = param[1]
        }
        return idToFetch;
    })())

    // eslint-disable-next-line
    const [cookieShowTooltips, setCookieShowTooltips] = useState(getItem("hideToolTip") === "true" ? false : true);
    const [ifValidInputs, setIfValidInputs] = useState(true)
    const [solutionsResults, setSolutionsResults] = useState([
        /*
        {
          solutionId: "",
          advSettings: "",
          advSettingsH: "",
          results: "",
          resultsH: "",
        },
        ...
        */
    ]);
    const [applicationId, setApplicationId] = useState('');
    const [baseSettings, setBaseSettings] = useState([]);

    //for UI
    const [showRightContent, setShowRightContent] = useState(false);
    const [showLeft, setShowLeft] = useState(true);

    const [showAdvSettings, setShowAdvSettings] = useState(false);


    // Per client's guide, disable this popup
    // const [showPopup, setShowPopup] = useState(false);
    const [notifyUpdate, setNotifyUpdate] = useState(false);

    const rightElRef = useRef();
    const id = props.id;

    const initJsonDataRef = useRef(
        require("../../configs/calculators/" + id + ".json")
    );





    const initSolutionsResults = (baseSettings, advSettingsNew, resultsNew) => {
        const newSolutionsResults = [];
        for (let solution of solutions) {
            const [newAdvSettings, newAdvSettingsH] = getSolutionedAdvSettings(
                JSON.parse(JSON.stringify(advSettingsNew)),
                JSON.parse(JSON.stringify(advSettingsNew)),
                solution.id
            );
            const newResults = calculate(
                baseSettings,
                newAdvSettings,
                JSON.parse(JSON.stringify(resultsNew))
            );
            const newResultsH = calculate(
                baseSettings,
                newAdvSettingsH,
                JSON.parse(JSON.stringify(resultsNew))
            );
            newSolutionsResults.push({
                solutionId: solution.id,
                advSettings: newAdvSettings,
                advSettingsH: newAdvSettingsH,
                results: newResults,
                resultsH: newResultsH,
            });
        }
        return newSolutionsResults;
    };

    const resetAll = (fedBaseSettings) => {
        let [baseSettingsNew, advSettingsNew, resultsNew] = divideJsonData(
            initJsonDataRef.current
        );
        if (fedBaseSettings) {
            baseSettingsNew = fedBaseSettings;
        } else {
            // If is not init from server, then sign a new id
            userId.current = uuidv4();
        }

        setBaseSettings(baseSettingsNew);
        const newSolutionsResults = initSolutionsResults(
            baseSettingsNew,
            advSettingsNew,
            resultsNew
        );
        setSolutionsResults(newSolutionsResults);

    };

    const divideJsonData = (jsonData) => {
        const copy = JSON.parse(JSON.stringify(jsonData));
        const baseSettingsNew = {};
        const advSettingsNew = {};
        const resultsNew = {};
        for (let key in copy) {
            if (copy[key].type === "base_setting") {
                baseSettingsNew[key] = copy[key];
            } else if (copy[key].type === "adv_setting") {
                advSettingsNew[key] = copy[key];
            } else {
                resultsNew[key] = copy[key];
            }
        }
        return [baseSettingsNew, advSettingsNew, resultsNew];
    };

    const calculate = (baseSettings, advSettings, results) => {
        const newResults = {
            ...results,
        };
        for (let key in newResults) {
            if (newResults[key].formula) {
                const mathFormula = formulaConvertor(newResults[key].formula, {
                    ...initJsonDataRef.current,
                    ...baseSettings,
                    ...advSettings,
                });
                const res = calculateMathFormula(mathFormula);
                newResults[key].value = res;
            }
        }

        return newResults;
    };

    const submitToServer = () => {
        // Submits the data to the server
        if (!formSubmitted.current) {
            postData(formData);
            formSubmitted.current = true;
        }
    }

    const getSolutionedAdvSettings = (
        advSettings,
        advSettingsH,
        solutionId
    ) => {
        const solutionValue =
            store.solutionsRef.current[solutionId][
            "value_" + store.curCurrency
            ];
        const settings = solutionValue.split(",").map((entry) => {
            const pair = entry.trim().split(":");
            const id = pair[0];
            const value = pair[1];
            return {
                id: id,
                value: value,
            };
        });

        const settingsL = settings.map((row) => {
            return {
                id: row.id,
                value: row.value.split("-")[0],
            };
        });
        const settingsH = settings.map((row) => {
            return {
                id: row.id,
                value: row.value.split("-")[1]
                    ? row.value.split("-")[1]
                    : row.value.split("-")[0],
            };
        });
        const newAdvSettings = { ...advSettings };
        const newAdvSettingsH = { ...advSettingsH };
        settingsL.forEach((row) => {
            newAdvSettings[row.id].value = row.value;
        });
        settingsH.forEach((row) => {
            newAdvSettingsH[row.id].value = row.value;
        });
        return [newAdvSettings, newAdvSettingsH];
    };


    useEffect(() => {
        let valid = true;
        Object.keys(baseSettings).forEach((key, index) => {
            const value = baseSettings[key].value
            valid = valid && value
        })
        setIfValidInputs(valid)
        const newConfigData = {};
        newConfigData["baseSettings"] = baseSettings;
        setConfigData(newConfigData);
        // eslint-disable-next-line
    }, [baseSettings])

    useEffect(() => {
        if (!firstRendering) {
            resetAll();
            window.scrollTo(0, 0);
        } else {
            firstRendering.current = false;
        }

        // eslint-disable-next-line
    }, [store.curCurrency]);


    useEffect(() => {
        if (ifHasIdRef.current) {
            axios.get(process.env.REACT_APP_SCRIPTS_SERVER + "/calls/load/index.php?id=" + ifHasIdRef.current)
                .then(function (response) {
                    console.log(response);
                    const fromServer = response.data.configData;
                    /**
                     * response.data.configData structure:
                      {
                        applicationId: string,
                        email: {},
                        facilityId: string,
                        zoneId: string,
                        configData: {
                          baseSettings: {},
                        },
                        formData: {},
                        userId: string,
                      }
                     */

                    setApplicationId(fromServer.applicationId);
                    userId.current = fromServer.userId;
                    resetAll(fromServer.configData.baseSettings);
                    setFormData(fromServer.formData);
                    setShowRightContent(true);
                    // setShowPopup(false);
                    setShowLeft(false);
                })
                .catch(function (error) {
                    console.log("GET error, init calculator using default config");
                    setApplicationId(store.applicationId)
                    resetAll()
                    openForm()
                    console.log(error)
                })
        } else {
            setApplicationId(store.applicationId)
            resetAll()
            openForm()

        }
        // eslint-disable-next-line
    }, [])

    if (!id) {
        return <ErrorPage />;
    }

    let theTitle = ""
    if (applicationsRef.current[applicationId]) {
        theTitle = applicationsRef.current[applicationId].name_on_report_page ?
            intl.formatMessage({ id: applicationId + ".name_on_report_page" }, { br: <br /> })
            :
            intl.formatMessage({ id: applicationId + ".name" }, { br: <br /> }) + " ROI REPORT"
    }

    return (
        <div className={classes.main}>
            <div className={classes.body}>
                <div className={[classes.left, "no-print", showLeft ? null : classes['collapse-left']].join(' ')}>
                    <div className={[classes['left-toggle'], showLeft ? classes['active'] : null].join(' ')}
                        onClick={() => { setShowLeft(prev => !prev) }}
                    />
                    {/* {
                        showPopup && cookieShowTooltips &&
                        <HoverBubble hoverDirection={"calculator-popup-right"} hoverX={'80%'} hoverY={'50%'} showBorder={true}>
                            <div className={classes["popup-body"]}>
                                <div className={["app-black-close", classes['close']].join(' ')}
                                    onClick={() => {
                                        setShowPopup(prevState => !prevState);
                                    }}
                                />
                                <h5>Get your personalized ROI calculation report</h5>
                                <p>
                                    Review the base application
                                    <br />
                                    settings and customize them
                                    <br />
                                    to better fit your facility.
                                </p>

                            </div>
                        </HoverBubble>
                    } */}
                    <div className={classes["left-body"]}>
                        <div className={classes["left-title"]}>
                            <button
                                onClick={() => history.push('/' + params.lang + "/" + store.zonesRef.current[store.zoneId].pathname + "/" + store.applicationsRef.current[applicationId].pathname + window.location.search)}
                                className={[
                                    "app-button-style-1",
                                    "app-back-arrow-parent",
                                    classes["back-btn"],
                                ].join(" ")}
                            >
                                <span className={"app-back-arrow"}>{intl.formatMessage({ id: "calculator.back" }, { br: <br /> })}</span>
                            </button>
                            <h3>{intl.formatMessage({ id: "calculator.title" }, { br: <br /> })}</h3>
                        </div>
                        <p>{intl.formatMessage({ id: "calculator.copy" }, { br: <br /> })}</p>
                        <div className={classes['base-settings']}>
                            {Object.keys(baseSettings).map((objKey) => {
                                const value = baseSettings[objKey].value
                                const isEmpty = value ? false : true;
                                const selections = baseSettings[objKey].selections
                                if (baseSettings[objKey].display_type === "hide") return null
                                if (selections)
                                    return <SelectElement
                                        key={objKey}
                                        objKey={objKey}
                                        baseSettings={baseSettings}
                                        setBaseSettings={setBaseSettings}
                                        isEmpty={isEmpty}
                                        showRightContent={showRightContent}
                                        setNotifyUpdate={setNotifyUpdate}
                                        selections={selections.split(";").map(e => e.trim())} />

                                return <InputElement
                                    key={objKey}
                                    objKey={objKey}
                                    baseSettings={baseSettings}
                                    setBaseSettings={setBaseSettings}
                                    isEmpty={isEmpty}
                                    showRightContent={showRightContent}
                                    setNotifyUpdate={setNotifyUpdate} />
                            })}
                        </div>
                        {
                            showAdvSettings &&
                            <div className={classes['adv-settings']}>
                                <h4>{intl.formatMessage({ id: "calculator.advSettings" }, { br: <br /> })}</h4>
                                <div className={classes['adv-settings-box']}>
                                    <p>{intl.formatMessage({ id: "calculator.throughput" }, { br: <br /> })}</p>
                                    {solutionsResults.map((solution, index) => {
                                        const solutionObj = solution;
                                        const setSolutionObj = (newSolution) => {
                                            const newSolutionsResults = [
                                                ...solutionsResults,
                                            ];
                                            newSolutionsResults[index] = newSolution;
                                            setSolutionsResults(newSolutionsResults);
                                            setNotifyUpdate(true);
                                        };
                                        return (
                                            <AdvSettingInputEl
                                                solutionObj={solutionObj}
                                                setSolutionObj={setSolutionObj}
                                                key={solutionObj.solutionId + index}
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        }

                        <br />
                        <div className={classes.buttons}>

                            <button
                                onClick={() => {
                                    // Get a new id for every calculate to make sure it works well even if user sharing results multiple times in different configuration
                                    userId.current = uuidv4();
                                    if (!ifValidInputs) return
                                    const newSolutionsResults = [
                                        ...solutionsResults,
                                    ];
                                    newSolutionsResults.forEach((solution) => {
                                        const newResults = calculate(
                                            baseSettings,
                                            solution.advSettings,
                                            solution.results
                                        );
                                        const newResultsH = calculate(
                                            baseSettings,
                                            solution.advSettingsH,
                                            solution.resultsH
                                        );
                                        solution.results = newResults;
                                        solution.resultsH = newResultsH;
                                    });
                                    setSolutionsResults(newSolutionsResults);
                                    setShowRightContent(true);
                                    // setShowPopup(false);
                                    setNotifyUpdate(false);
                                    setShowLeft(false);
                                    submitToServer();
                                }}
                                className={[notifyUpdate ? ["app-button-style-2", "app-jump-animate"].join(" ") : "app-button-style-blue-fill", 'gtm-calculate'].join(" ")}
                            >
                                {
                                    notifyUpdate ?
                                        intl.formatMessage({ id: "app.update" }, { br: <br /> })
                                        :
                                        intl.formatMessage({ id: "app.calculateROI" }, { br: <br /> })
                                }
                            </button>
                            {
                                store.config.disableAdvSettings ?
                                    null :
                                    <button
                                        onClick={() => setShowAdvSettings(!showAdvSettings)}
                                        className={["app-button-style-1"].join(" ")}
                                    >
                                        {intl.formatMessage({ id: "calculator.advSettings" }, { br: <br /> })}
                                    </button>
                            }

                        </div>
                    </div>
                </div>
                {
                    // right part
                }
                <div className={classes.right} ref={rightElRef}>
                    {showRightContent ? (
                        <div>
                            {showLeft && <div className={classes['black-shadow']}></div>}
                            <TheResults
                                applicationId={applicationId}
                                baseSettings={baseSettings}
                                solutionsResults={solutionsResults}
                                setShowLeft={setShowLeft}
                            />
                        </div>
                    ) : (
                        <div className={classes["right-mask-wrapper"]}>
                            <h1>{theTitle}</h1>
                            <div className={classes["right-mask"]}>
                                <img src={mockImg1} alt='mock' />
                                <img src={mockImg2} alt='mock' />
                                <img src={mockImg3} alt='mock' />
                            </div>
                        </div>

                    )}
                </div>

                {
                    // tooltip control part
                }
                {
                    // !cookieShowTooltips?
                    //     <button className={classes['tooltip-btn']} onClick={() => {
                    //         setItem("hideToolTip", "true", -1)
                    //         setCookieShowTooltips(true)
                    //     }}><img src={iImg} alt="" /></button>
                    //     : null
                }

            </div>
        </div>
    );
}

export default Calculator;



const InputElement = (props) => {
    const key = props.objKey
    const store = useContext(GlobalStore);
    const baseSettings = props.baseSettings;
    const setBaseSettings = props.setBaseSettings
    const isEmpty = props.isEmpty
    const showRightContent = props.showRightContent
    const setNotifyUpdate = props.setNotifyUpdate
    const [showSubtitle, setShowSubtitle] = useState(false);
    const calculatorId = store.applicationsRef.current[store.applicationId].calculator_id
    const intl = useIntl()
    return (
        <div
            key={baseSettings[key].id}
            className={[
                classes["left-input"],
                "app-input-group",
            ].join(" ")}
        >
            <div className={[
                classes["left-input-title-row"],
            ].join(" ")}>
                <p>
                    {intl.formatMessage({ id: calculatorId + "." + key + ".variableName" }, { br: <br /> })}
                    {getSuffix(
                        baseSettings[key].display_type,
                        store.currenciesRef.current[store.curCurrency]['symbol']
                    )}
                </p>
                {
                    baseSettings[key].note &&
                    <div className={classes["left-input-title-row-icon"]}>
                        <img src={infoImg} alt="i" onClick={() => { setShowSubtitle(prev => !prev) }} />
                    </div>
                }
            </div>
            {
                showSubtitle && <p style={{ marginBottom: "1rem" }} className={"app-animate-expend-from-top"}>{baseSettings[key].note}</p>
            }

            <input
                className={isEmpty ? classes['invalid-input'] : null}
                value={baseSettings[key].value}
                onChange={(e) => {
                    const isnum = /^[+-]?([0-9]+\.?[0-9]*|\.[0-9]+)$/;
                    // validation check
                    if (!isnum.test(e.target.value) && e.target.value !== '') return
                    const copy = { ...baseSettings };
                    copy[key].value = e.target.value;
                    setBaseSettings(copy);
                    if (showRightContent)
                        setNotifyUpdate(true);
                }}
            />
        </div>
    );
}


const SelectElement = (props) => {
    const store = useContext(GlobalStore);
    const intl = useIntl()
    const [showSubtitle, setShowSubtitle] = useState(false);
    const { objKey, baseSettings, setBaseSettings, isEmpty, showRightContent, setNotifyUpdate, selections } = props
    const calculatorId = store.applicationsRef.current[store.applicationId].calculator_id
    return (
        <div
            key={baseSettings[objKey].id}
            className={[
                classes["left-input"],
                "app-select-group",
            ].join(" ")}
        >
            <div className={[
                classes["left-input-title-row"],
            ].join(" ")}>
                <p>
                    {intl.formatMessage({ id: calculatorId + "." + objKey + ".variableName" }, { br: <br /> })}
                    {getSuffix(
                        baseSettings[objKey].display_type,
                        store.currenciesRef.current[store.curCurrency]['symbol']
                    )}
                </p>
                {
                    baseSettings[objKey].note &&
                    <div className={classes["left-input-title-row-icon"]}>
                        <img src={infoImg} alt="i" onClick={() => { setShowSubtitle(prev => !prev) }} />
                    </div>
                }
            </div>
            {
                showSubtitle && <p style={{ marginBottom: "1rem" }} className={"app-animate-expend-from-top"}>{baseSettings[objKey].note}</p>
            }
            <select
                className={isEmpty ? classes['invalid-input'] : null}
                value={baseSettings[objKey].value}
                onChange={(e) => {
                    const copy = { ...baseSettings };
                    copy[objKey].value = e.target.value;
                    setBaseSettings(copy);
                    if (showRightContent)
                        setNotifyUpdate(true);
                }}
            >
                {
                    selections.map(selection => {
                        return <option key={selection} value={selection} option={{ selected: true }}>{selection}</option>
                    })
                }
            </select>
        </div>
    );
}