

export function formulaConvertor(idFormula, jsonData) {
    if (!idFormula) return ''
    // Read the id Identifier character from jsonData (i.e. the Column ID used in Excel)
    const idCharacter = Object.keys(jsonData)[0][0]
    let mathFormula = ''
    for (let i = 0; i < idFormula.length; i++) {
        // the first sign is '=' in our data sheet, generated by FORMULATEXT() function in google sheet
        // $ is a spacial charactor may occur
        if (idFormula[i] === '=' || idFormula[i] === '$') continue
        if (idFormula[i] === idCharacter) {
            i++
            if (i === idFormula.length) break
            let id = idCharacter

            while (!isNaN(parseInt(idFormula[i]))) {
                id = id + idFormula[i]
                i++
                if (i === idFormula.length) {
                    break;
                }
            }
            i--;
            // if the id is type of result:
            if (jsonData[id].type === 'result') {
                mathFormula +=
                    '(' + formulaConvertor(jsonData[id].formula, jsonData) + ')'
            } else {
                mathFormula += jsonData[id].value
            }
        }
        // add calc signs back
        else mathFormula = mathFormula + idFormula[i]
    }
    return mathFormula
}


export function calculateMathFormula(mathFormula) { // Note: * must be writen, like: 4*(2+2), can't be 4(2+2)
    return calculateMathFormula_core(mathFormula, 0)[0]
}


function calculateMathFormula_core(mathFormula, index) {
    let res = 0
    let sign = '+'
    const n = mathFormula.length
    const idFormula = [...mathFormula]
    const stack = new Array(n)
    let p = 0
    for (let i = index; i < n; i++) {

        if (idFormula[i] === ' ') continue
        if (
            idFormula[i] === '+' ||
            idFormula[i] === '-' ||
            idFormula[i] === '*' ||
            idFormula[i] === '/'
        ) {
            sign = idFormula[i]
            continue
        }
        else if (idFormula[i] === '(') {
            const [cur, index] = calculateMathFormula_core(mathFormula, i + 1)
            i = index
            if (sign === '+') stack[p++] = cur
            else if (sign === '-') stack[p++] = -cur
            else if (sign === '*') stack[p - 1] = cur * stack[p - 1]
            else if (sign === '/') {
                if (cur !== 0)
                    stack[p - 1] = stack[p - 1] / cur
                else stack[p - 1] = -1
            }
            else {
                console.log("Invalid sign:" + sign);
            }
        }
        else if (idFormula[i] === ')') {
            for (let i in stack) {
                res += stack[i]
            }
            return [res, i]
        }
        else if (!isNaN(parseInt(idFormula[i]))) {
            let cur = ''
            while (idFormula[i] === '.' || !isNaN(parseInt(idFormula[i]))) {
                cur += idFormula[i]
                i++
                if (i === idFormula.length) {
                    break;
                }
            }
            i--
            cur = parseFloat(cur)
            if (sign === '+') stack[p++] = cur
            else if (sign === '-') stack[p++] = -cur
            else if (sign === '*') stack[p - 1] = cur * stack[p - 1]
            else if (sign === '/') {
                if (cur !== 0)
                    stack[p - 1] = stack[p - 1] / cur
                else stack[p - 1] = -1
            }
            else {
                console.log("Invalid sign:" + sign);
            }

        }
    }
    for (let i in stack) {
        res += stack[i]
    }
    return [res, n]
}

export function resCurrencyFormatter(number, displayType, currencySymbol) {
    const trimed = displayType.trim()
    let floatNum = parseFloat(number)
    const sign = floatNum < 0 ? '-' : '';
    floatNum = Math.abs(floatNum)
    if (trimed === 'percentage') return sign + Math.round(100 * floatNum) + "%"
    else if (trimed === 'round_ceil') return Math.ceil(floatNum)
    else if (trimed === 'currency') {
        const stringNum = floatNum + ''
        const numbers = stringNum.split('.')
        let integer = numbers[0]
        let decimals = numbers[1]
        // deal with integer
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i--) {
            counter++
            if (counter === 3 && i !== 0) {
                integer = integer.slice(0, i) + ',' + integer.slice(i, integer.length)
                counter = 0
            }
        }

        // deal with numbers[1]
        if (parseFloat("0." + decimals) >= 0.005) {
            if (decimals[2]) {
                if (decimals[2] >= '5') {
                    decimals = [...decimals]
                    decimals[1] = parseInt(decimals[1]) + 1
                    decimals = decimals.join('')
                }
            }
            if (!decimals[1]) decimals = decimals + '0'
            decimals = decimals.slice(0, 2)
            return sign + currencySymbol + integer + '.' + decimals
        }
        else return sign + currencySymbol + integer

    }
    else if (trimed === 'currency_k') {
        const intNumber = parseInt(number)
        return currencySymbol + Math.ceil(intNumber / 1000) + "K"
    }
    else if (trimed === 'currency_m') {
        const intNumber = parseInt(number)
        return currencySymbol + Math.ceil(intNumber / 1000) + "M"
    }
    else if (trimed === 'n_formatter') {
        // This type of displays was introduced when different solutions needed different types of displays (K or M), 
        // so this will calculate the value in order to render the corresponding symbol
        const intNumber = parseInt(number)
        //const num_round = Math.ceil(intNumber) // Round up
        const digits = 0; // This can be change as needed (e.g. 0, 1, 2, etc)
        
        const lookup = [
            { value: 1, symbol: "" },
            { value: 1e3, symbol: "k" }, // One Thousand
            { value: 1e6, symbol: "M" }, // One Million
        ];
        
        const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
        var item = lookup.slice().reverse().find(function(item) {
            return intNumber >= item.value;
        });
        return item ? (intNumber / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";

    }

    else if (trimed === 'number') {
        const stringNum = floatNum + ''
        const numbers = stringNum.split('.')
        let integer = numbers[0]
        let decimals = numbers[1]
        // deal with integer
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i--) {
            counter++
            if (counter === 3 && i !== 0) {
                integer = integer.slice(0, i) + ',' + integer.slice(i, integer.length)
                counter = 0
            }
        }

        // deal with numbers[1]
        if (parseFloat("0." + decimals) >= 0.005) {
            if (decimals[2]) {
                if (decimals[2] >= '5') {
                    decimals = [...decimals]
                    decimals[1] = parseInt(decimals[1]) + 1
                    decimals = decimals.join('')
                }
            }
            if (!decimals[1]) decimals = decimals + '0'
            decimals = decimals.slice(0, 2)
            return sign + integer + '.' + decimals
        }
        else return sign + integer

    }
    else if (trimed === 'number_k') {
        const intNumber = parseInt(number)
        if (intNumber <= 1000) return intNumber
        return Math.ceil(intNumber / 1000) + "K"
    }
    else if (trimed === 'year') {
        const stringNum = floatNum / 365 + ''
        const numbers = stringNum.split('.')
        let integer = numbers[0]
        let decimals = numbers[1]
        // deal with integer
        let counter = 0;
        for (let i = integer.length - 1; i >= 0; i--) {
            counter++
            if (counter === 3 && i !== 0) {
                integer = integer.slice(0, i) + ',' + integer.slice(i, integer.length)
                counter = 0
            }
        }

        // deal with numbers[1]
        if (parseFloat("0." + decimals) >= 0.005) {
            if (decimals[2]) {
                if (decimals[2] >= '5') {
                    decimals = [...decimals]
                    decimals[1] = parseInt(decimals[1]) + 1
                    decimals = decimals.join('')
                }
            }
            if (!decimals[1]) decimals = decimals + '0'
            decimals = decimals.slice(0, 2)
            return integer + '.' + decimals
        }
        else return integer
    }
    else if (trimed === 'month') {
        return Math.ceil(floatNum / 30)
    }

    else return number
}

export function getSuffix(display_type, currencySymbol) {
    if (display_type.trim() === 'currency')
        return " (" + currencySymbol + ")"
    else if (display_type.trim() === 'percentage')
        return " (%)"
    else return ''
}




