
import classes from './FacilityMap.module.scss';
import GlobalStore from '../../contexts/GlobalStore';
import ZoneBlock from '../../components/ZoneBlock/ZoneBlock';
import React, { useContext, useEffect, useState, useRef } from 'react';
import mapBg from '../../assets/images/facility01.png'
import MapSidebar from './MapSidebar/MapSidebar';
import MapControlPanel from '../../components/MapControlPanel/MapControlPanel';
import MapInstruction from '../../components/MapInstruction/MapInstruction';
import { useParams, useHistory, useLocation } from 'react-router';
import ErrorPage from '../ErrorPage/ErrorPage'
import { useIntl } from 'react-intl';


function FacilityMap(props) {
  const store = useContext(GlobalStore)

  const {
    thisPage,
  } = props;
  const {
    getZoneIdByPathname,
    getApplicationIdByPathname,
  } = store;
  const history = useHistory()
  const params = useParams()
  const location = useLocation()
  const pathnames = location.pathname.split('/')
  const intl = useIntl()

  const facilityAndZoneRef = useRef(require('../../configs/facilityAndZone.json'))
  const facilityAndZones = facilityAndZoneRef.current.filter((entry) => entry['facility_id'] === store.facilityId)
  const zoneAndApplicationRef = useRef(require('../../configs/zoneAndApplication.json'))

  const [validUrl, setValidUrl] = useState(true)
  const [scale, setScale] = useState(1)
  const [positionY, setPositionY] = useState(0)
  const [positionX, setPositionX] = useState(0)
  const [applications, setApplications] = useState([])
  const [headerElHeight, setHeaderElHeight] = useState(0)
  const [showMapSidebarBody, setShowMapSidebarBody] = useState(true)
  const [appHovering, setAppHovering] = useState("")



  const [dragging, setDragging] = useState(false)
  const onStartXRef = useRef(0);
  const onStartYRef = useRef(0);
  const prevXRef = useRef(0);
  const prevYRef = useRef(0);
  const mouseOnMapBodyRef = useRef(false);
  const mapWindowElRef = useRef();
  const mapBodyElRef = useRef();
  const mapImgElRef = useRef();
  const headerElRef = useRef();
  const controllParams = useRef({
    scaleMin: 0.6,
    scaleMax: 2,
    topMin: 0,
    topMax: 0,
    leftMin: 0,
    leftMax: 0,
    clickZoominScale: 1.6
  })


  const mapBodyStyle = {
    transform: 'scale(' + scale + ')',
    top: positionY + 'px',
    left: positionX + 'px'
  }

  const setScaleHelper = (newScale) => {
    if (newScale >= controllParams.current.scaleMax) {
      setScale(controllParams.current.scaleMax)
    }
    else if (newScale <= controllParams.current.scaleMin) {
      setScale(controllParams.current.scaleMin)
    } else {
      setScale(newScale)
    }
  }


  const mapBodyDragStart = (e) => {
    e.stopPropagation()
    setDragging(true)
    onStartXRef.current = e.pageX;
    onStartYRef.current = e.pageY;
    prevXRef.current = positionX
    prevYRef.current = positionY
  }

  const mapBodyDrag = (e) => {
    e.stopPropagation()
    if (!dragging) return
    const newX = e.pageX - onStartXRef.current + prevXRef.current;
    const newY = e.pageY - onStartYRef.current + prevYRef.current;
    setPositionY(newY)
    setPositionX(newX)

  }

  const mapBodyDragEnd = (e) => {
    setDragging(false)
  }

  const appScrollHandler = (e) => {
    if (mouseOnMapBodyRef.current) {
      e.preventDefault();
      return
    }
  }

  const mapBodyScrollHandler = (e) => {
    if (e.deltaY > 0) {
      setScaleHelper(scale - 0.1)
    }
    else if (e.deltaY < 0) {
      setScaleHelper(scale + 0.1)
    }

  }




  const urlNav = () => {
    if (params.zone) {
      let zoneId = getZoneIdByPathname(params.zone)
      if (zoneId)
        store.setZoneId(zoneId)
      else {
        setValidUrl(false)
      }
    }
    if (params.application) {
      let applicationId = getApplicationIdByPathname(params.application)
      if (applicationId)
        store.setApplicationId(applicationId)
      else
        setValidUrl(false)
    }
  }



  const resetAll = () => {
    store.setApplicationId('')
    store.setZoneId('')
    setScale(1)
    setPositionX(0)
    setPositionY(0)
    history.push('/' + params.lang + '/' + thisPage.pathname + window.location.search)
  }

  //  In component url validation check
  useEffect(() => {
    if (params.zone) {
      let zoneId = getZoneIdByPathname(params.zone)
      if (zoneId) { }
      else {
        setValidUrl(false)
      }
    }
    if (params.application) {
      let applicationId = getApplicationIdByPathname(params.application)
      if (applicationId) { }
      else
        setValidUrl(false)
    }
    // eslint-disable-next-line
  }, [params])

  useEffect(() => { //set pageId
    document.title = thisPage.document_title;
    store.setCurPageId(thisPage.id)
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (!validUrl) return
    // operation to run if autoNavToOn
    const autoNavToOperation = () => { store.setZoneId(props.autoNavTo.zoneId); store.setApplicationId(props.autoNavTo.applicationId) }

    if (props.autoNavTo.autoNavToOn && pathnames[2] === thisPage.pathname) {
      // console.log("auto nav actived");
      mapImgElRef.current.complete ?
        autoNavToOperation() :
        mapImgElRef.current.decode().then(autoNavToOperation)

    } else if (pathnames[2] !== thisPage.pathname) {
      // Url nav
      mapImgElRef.current.complete ?
        urlNav() :
        mapImgElRef.current.decode().then(urlNav)
    }
    document.getElementsByClassName("App")[0].addEventListener("wheel", appScrollHandler)
    return () => {
      if (document.getElementsByClassName("App")[0])
        document.getElementsByClassName("App")[0].removeEventListener("wheel", appScrollHandler)
    }
    // eslint-disable-next-line
  }, [])

  const updateLimitation = (scale) => {
    if (!validUrl || !mapWindowElRef.current) { return }
    const minH = Math.min(mapWindowElRef.current.clientHeight, mapBodyElRef.current.clientHeight)
    const minW = Math.min(mapWindowElRef.current.clientWidth, mapBodyElRef.current.clientWidth)
    const bodyUperBoundY = -(scale - 1) * mapBodyElRef.current.clientHeight / 2
    const bodyDownBoundY = bodyUperBoundY + mapBodyElRef.current.clientHeight * scale
    const bodyLeftBoundX = -(scale - 1) * mapBodyElRef.current.clientWidth / 2
    const bodyRightBoundX = bodyLeftBoundX + mapBodyElRef.current.clientWidth * scale
    controllParams.current.topMax = Math.max(mapWindowElRef.current.clientHeight - bodyUperBoundY - minH, minH - bodyDownBoundY)
    controllParams.current.topMin = Math.min(mapWindowElRef.current.clientHeight - bodyUperBoundY - minH, minH - bodyDownBoundY)
    controllParams.current.leftMax = Math.max(mapWindowElRef.current.clientWidth - bodyLeftBoundX - minW, minW - bodyRightBoundX)
    controllParams.current.leftMin = Math.min(mapWindowElRef.current.clientWidth - bodyLeftBoundX - minW, minW - bodyRightBoundX)
    if (showMapSidebarBody) {
      controllParams.current.leftMax += 0.5 * mapWindowElRef.current.clientWidth
    }
  }

  useEffect(() => {
    if (!validUrl) return
    mapImgElRef.current.complete ?
      updateLimitation(scale) :
      mapImgElRef.current.decode().then(() => updateLimitation(scale))
    // eslint-disable-next-line
  }, [scale, showMapSidebarBody])

  // eslint-disable-next-line
  useEffect(() => {
    if (!dragging) {
      if (positionY > controllParams.current.topMax) setPositionY(controllParams.current.topMax)
      if (positionY < controllParams.current.topMin) setPositionY(controllParams.current.topMin)
      if (positionX > controllParams.current.leftMax) setPositionX(controllParams.current.leftMax)
      if (positionX < controllParams.current.leftMin) setPositionX(controllParams.current.leftMin)
    }
  })


  useEffect(() => {
    const zoneAndApplications = zoneAndApplicationRef.current.filter((entry) => entry['zone_id'] === store.zoneId)
    const newApplications = new Set();
    zoneAndApplications.forEach(entry => {
      newApplications.add(entry.application_id);
    })
    setApplications(Array.from(newApplications).sort((a, b) => store.applicationsRef.current[a].order - store.applicationsRef.current[b].order))

    // for url
    if (store.zoneId && !pathnames[3]) {
      const newPathname = '/' + params.lang + '/' + store.zonesRef.current[store.zoneId].pathname
      if (newPathname !== location.pathname) {
        history.push(newPathname + window.location.search)
      }
    }

    // tem code, to be removed after the order Fulfillment zone has more than one application - This select an applicaiton automatically, change as needed
    //if(store.zoneId === "zone_01"){
    //store.setApplicationId("application_01");
    //}

    // eslint-disable-next-line
  }, [store.zoneId])


  useEffect(() => {
    // for url
    // assume must have a zone id before choose a application
    if (store.zoneId && store.applicationId) {
      const newPathname = '/' + params.lang + '/' + store.zonesRef.current[store.zoneId].pathname + '/' + store.applicationsRef.current[store.applicationId].pathname
      if (newPathname !== location.pathname)
        history.push(newPathname + window.location.search)
    } else if (store.zoneId && !store.applicationId) { // if application id is removed
      const newPathname = '/' + params.lang + '/' + store.zonesRef.current[store.zoneId].pathname
      if (newPathname !== location.pathname)
        history.push(newPathname + window.location.search)
    }
    // eslint-disable-next-line
  }, [store.applicationId])

  // eslint-disable-next-line
  useEffect(() => {
    if (headerElRef.current)
      setHeaderElHeight(headerElRef.current.clientHeight)
  })


  if (!validUrl) {
    store.setCurPageId(null);
    return <ErrorPage />;
  }

  return (
    <div className={classes.main}>
      <MapInstruction></MapInstruction>
      <MapControlPanel scale={scale} setScaleHelper={setScaleHelper} resetAll={resetAll}></MapControlPanel>
      <div className={[classes.body].join(' ')} >

        {// State 2: sidebar and header application buttons
          store.zoneId ?
            <React.Fragment>
              <div className={classes['header']}
                ref={headerElRef}
              >
                {
                  applications.map(application_id => {
                    return <h3 key={application_id}
                      onClick={() => {
                        store.setApplicationId(application_id)
                      }}
                      className={[
                        application_id === store.applicationId ? classes['header-h3-active'] : null,
                        store.applicationsRef.current[application_id].avaliable === "TRUE" ? "" : "app-display-none"
                      ].join(" ")}
                    // >{store.applicationsRef.current[application_id].name}</h3>
                    >{intl.formatMessage({ id: application_id + ".name" }, { br: <br /> })}</h3>
                  })
                }
              </div>
              <MapSidebar headerHeight={headerElHeight} applications={applications} nextPage={props.nextPage} showMapSidebarBody={showMapSidebarBody} setShowMapSidebarBody={setShowMapSidebarBody} lockMapSidebar={props.lockMapSidebar}></MapSidebar>
            </React.Fragment>
            : null
        }

        <div
          className={[classes.map, dragging ? classes.dragging : null, store.applicationId ? classes['dark-bg'] : null].join(' ')}
          ref={mapWindowElRef}
          onMouseMove={mapBodyDrag}
          onMouseUp={mapBodyDragEnd}
          onMouseDown={mapBodyDragStart}
          onWheel={mapBodyScrollHandler}
          onMouseEnter={() => { mouseOnMapBodyRef.current = true }}
          onMouseLeave={() => { mapBodyDragEnd(); mouseOnMapBodyRef.current = false }}
        >
          <div className={[classes['map-body'], dragging ? classes['body-dragging'] : ''].join(' ')} style={mapBodyStyle}
            ref={mapBodyElRef}
          >
            <img ref={mapImgElRef} src={mapBg} alt='map-bg' draggable="false" className={store.applicationId ? classes.dark : null} />

            <div className={[classes['map-body-overlay'],].join(' ')}></div>

            {
              facilityAndZones.map((record) => {

                // disable this zone if it's not ready to lunch yet
                if (store.zonesRef.current[record.zone_id].avaliable === 'FALSE') return null
                return <ZoneBlock
                  appHovering={appHovering}
                  setAppHovering={setAppHovering}
                  dragging={dragging}
                  scale={scale}
                  setScale={setScale}
                  clickZoominScale={controllParams.current.clickZoominScale}
                  mapWindowElRef={mapWindowElRef}
                  mapBodyElRef={mapBodyElRef}
                  mapImgElRef={mapImgElRef}
                  setPositionY={setPositionY}
                  setPositionX={setPositionX}
                  updateLimitation={updateLimitation}
                  facilityAndZone={record} nextPage={props.nextPage} key={record['zone_id']}></ZoneBlock>;
              })
            }
          </div>
        </div>

      </div>

    </div>
  );
}

export default FacilityMap;
