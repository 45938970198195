
import classes from './MapInstruction.module.scss';
import left from '../../assets/images/left.png'
import right from '../../assets/images/right.png'
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';





function MapInstruction(props) {
const intl = useIntl()
const [show, setShow] = useState(false)
  useEffect(
    () => {
      setShow(true);
      let mounted = true;
      setTimeout(() => {
        if (mounted)
          setShow(false)
      }, 2000)
      return () => { mounted = false }
    }, [])
  return (
    <div className={[classes.main, show ? classes['show'] :'' ].join(' ')}>
      <div className={classes.body}>
        <div className={classes.left}>
          <div className={classes['left-img']}>
            <img src={left} alt='left'></img>
          </div>
          <p><b>{intl.formatMessage({ id: "mapInstruction.explore" }, { br: <br /> })}</b></p>
        </div>
        <div className={classes.right}>
          <div className={classes['right-img']}>
            <img src={right} alt='right'></img>
          </div>
          <p><b>{intl.formatMessage({ id: "mapInstruction.zoom" }, { br: <br /> })}</b></p>
        </div>
      </div>
    </div>
  );
}

export default MapInstruction;
