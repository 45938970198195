import { LocaleInfo } from "../interfaces/LocaleInfo";
import localeInfosRow from "../configs/regions/forLocale/regions.json";

function useLocaleInfo(input: string) {
    const localeInfoMap: { [key: string]: LocaleInfo } = localeInfosRow;
    const locale: string = input ? input : navigator.language;
    let res: LocaleInfo = localeInfoMap["en-us"];
    if (localeInfoMap[locale.toLowerCase()]) {
        res = localeInfoMap[locale.toLowerCase()];
    }
    return res;
}

export default useLocaleInfo;
